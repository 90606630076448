/* eslint-disable jsx-a11y/alt-text */
import React, { Fragment, useState, useEffect, useContext } from "react";

import { Col, Row, Tabs, Tab, Alert } from "react-bootstrap";
import MetisMenu from "metismenujs";
import Select from "react-select";
import { firebase, firestore } from "../../../firebase";
import Lottie from "react-lottie";
import loadingStethoscope from "../../56363-stethoscope-doctor.json";
import logo from "../../../images/m-logo-text.png";
import PerfectScrollbar from "react-perfect-scrollbar";
import ReactPaginate from "react-paginate";

/// images
import { Link } from "react-router-dom";
import TinyView from "../../components/Editor/tiny";
import AuthContext from "../../../context";
import DemoQuestions from "./demo_qs";

class MM extends React.Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }
  componentWillUnmount() {
    //  this.mm("dispose");
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const createOption = (label, value) => ({
  label,
  value: label.toLowerCase().replace(/\W/g, ""),
});
const PER_PAGE = 15;
const defaultTopic = {
  label: "Contraception",
  value: "Contraception",
};

let keyMap = {};
const Library = () => {
  const authUser = firebase?.auth()?.currentUser;
  const { userType } = useContext(AuthContext);
  const [activeAccordionHeaderBg, setActiveAccordionHeaderBg] = useState(0);
  const [finalTopics, setFinalTopics] = useState({});

  const [expanded, setExpanded] = useState({});
  const [expandedItems, setExpandedItems] = useState({});
  const [questions, setQuestions] = useState({});
  const [notes, setNotes] = useState({});
  const [filters, setFilters] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [examOptions, setExamOptions] = useState([]);
  const [topicOptions, setTopicOptions] = useState([]);
  const [subTopicOptions, setSubTopicOptions] = useState([]);
  const [titleOptions, setTitleOptions] = useState([]);
  const [studyessentialQsOnly, setStudyessentialQsOnly] = useState(false);
  window.document.addEventListener("keydown", function (e) {
    if (
      (e.keyCode === 44 ||
        e.keyCode === 51 ||
        e.keyCode === 52 ||
        e.keyCode === 83 ||
        e.keyCode === 115) &&
      keyMap[17]
    ) {
      alert(
        "Screen prints or any form of screen capture violates the terms and conditions of Medrevisions.com"
      );
      window.location.reload();
      return false;
    } else if (e.keyCode === 17) {
      keyMap[17] = true;
    } else {
      let keyMap = {};
    }
  });
  document.addEventListener("copy", function (e) {
    e.clipboardData.setData("text/plain", "");
    e.clipboardData.setData("text/html", "");
    e.preventDefault();
  });
  /** TO DISABLE SCREEN CAPTURE **/
  document.addEventListener("keyup", (e) => {
    if (e.key == "PrintScreen") {
      navigator.clipboard.writeText("");
      alert(
        "Screen prints or any form of screen capture violates the terms and conditions of Medrevisions.com"
      );
      window.location.reload();
      return false;
    }
  });

  /** TO DISABLE PRINTS WHIT CTRL+P **/
  document.addEventListener("keydown", (e) => {
    if (e.ctrlKey && e.key == "p") {
      alert(
        "Screen prints or any form of screen capture violates the terms and conditions of Medrevisions.com"
      );
      e.cancelBubble = true;
      e.preventDefault();
      e.stopImmediatePropagation();
      window.location.reload();
      return false;
    }
  });

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const userSnap = await firestore
          .collection("users")
          .doc(user.uid)
          .get();

        setStudyessentialQsOnly(userSnap.data().studyessentialQsOnly || false);
        // topic: "Contraception"
        // fetSubscriptions(user.uid);
        // fetchReferals(user.uid);
        if (userType === "demo_user") {
          setQuestions(DemoQuestions);
          setSeletedId(Object.keys(DemoQuestions)[0]);
        }
        if (userType !== "demo_user" || userType !== "expaird_user") {
          fethInialData();
          const defaultExam = {
            label: "PLAB",
            value: "PLAB",
          };
          //quiestionFilter("exam", defaultExam);
          //quiestionFilter("topic", defaultTopic);
          quiestionFilter(
            "exam",
            defaultExam,
            userSnap.data().studyessentialQsOnly
          );
          quiestionFilter(
            "topic",
            defaultTopic,
            userSnap.data().studyessentialQsOnly
          );
        }
      } else {
        // setQuestions(DemoQuestions);
        // setSeletedId(Object.keys(DemoQuestions)[0]);
        // User not logged in or has just logged out.
      }
    });
  }, []);

  const questionsRef = (filters) => {
    let ref = firestore.collection("questions");
    Object.keys(filters || {}).forEach((filter) => {
      if (filters[filter]) {
        ref = ref.where(filter, "==", filters[filter]);
      }
    });
    return ref;
  };

  const fethInialData = async () => {
    try {
      // this.setState({ isLoading: true });
      setIsLoading(true);

      let subTopicOptions = [];
      let topicOptions = [];
      let examOptions = [];

      // const examsRef = firestore.collection("exams");
      const topicRef = firestore.collection("topics");
      const subTopicRef = firestore
        .collection("subTopics")
        .where("topic", "==", "internalmedicine");

      // const examsSnapshot = await examsRef.get();
      const topicSnapshot = await topicRef.get();
      const subTopicSnapshot = await subTopicRef.get();

      // examsSnapshot.forEach((doc) => {
      //   examOptions.push(createOption(doc.data().name));
      // });

      topicSnapshot.forEach((doc) => {
        if (doc.data().name !== "anatomy") {
          topicOptions.push(createOption(doc.data().name));
        }
      });

      subTopicSnapshot.forEach((doc) => {
        subTopicOptions.push(createOption(doc.data().name));
      });

      setIsLoading(false);
      setExamOptions(examOptions);
      setTopicOptions(topicOptions);
      setSubTopicOptions(subTopicOptions);

      // this.setState({
      //   isLoading: false,
      //   examOptions,
      //   topicOptions,
      //   subTopicOptions
      // });
    } catch (e) {
      // alert(
      //   "This page is restricted for admins only. Please make sure you are signed in as an admin to continue"
      // );
    }
  };

  const getQuestions = async (filters) => {
    try {
      setIsLoading(true);

      let questions = {};
      const querySnapshot = await questionsRef(filters).get();
      querySnapshot.forEach((doc) => {
        // let questions_count = 0;
        const data = doc.data();

        // if (
        //   Object.keys(questions).some((v) => questions[v].title === data.title)
        // ) {
        //   questions_count++;
        // }
        const qus_id = Object.keys(questions).find(
          (v) => questions[v].title === data.title
        );

        if (!qus_id) {
          questions[doc.id] = {
            // pastPaperQuestion: data.pastPaperQuestion,
            id: doc.id,
            _description: data?._description,
            revisionNotes: data?.revisionNotes.replace(
              /(<table\b[^>]*\bstyle=["'][^"']*)\bwidth\s*:\s*[^;]+;?/gi,
              "$1"
            ),
            // exam: data.exam,
            title: data.title,
            topic: data.topicId,
            helpfulLinks: data?.helpfulLinks,
            // publish: data.publish ? "Yes" : "No",
            subTopic: data.subTopicId,
            questions_count: 1,
            // answerCount: Object.keys(data.answers).length,
          };
        } else {
          questions[qus_id].questions_count =
            questions[qus_id]?.questions_count + 1;
        }
      });

      setIsLoading(false);
      setQuestions(questions);
      let keys = Object.keys(questions);
      setSeletedId(keys[0]);
    } catch (error) {
      console.error("error=>", error);
      setIsLoading(false);
    }
    // this.setState({
    //   questions,
    //   isLoading: false
    // });
  };

  // const updatePastPaperQuestion = (questionId, checked) => {
  //   firestore
  //     .collection("questions")
  //     .doc(questionId)
  //     .set({ pastPaperQuestion: !checked }, { merge: true })
  //     .then(() => {
  //       const q = questions;
  //       q[questionId].pastPaperQuestion = !checked;
  //       // this.setState({ questions: q });
  //       setQuestions(q);
  //     });
  // };
  const defaultSubTopic = {
    label: "Cardiology",
    value: "Cardiology",
  };
  const quiestionFilter = async (name, value, se) => {
    const _filters = Object.assign({}, filters);
    if (value) {
      _filters[name] = value.label;
    } else {
      _filters[name] = null;
    }

    if (se) {
      _filters["studyessentialQuestion"] = true;
    } else {
      _filters["studyessentialQuestion"] = false;
    }

    if (_filters.topic !== "Internal Medicine" && _filters.subTopic) {
      delete _filters.subTopic;
    }
    setFilters(_filters);

    // subTopic: "Cardiology"
    if (_filters.topic === "Internal Medicine" && !_filters?.subTopic) {
      _filters.subTopic = "Cardiology";
      getQuestions(_filters);
    } else if (_filters.topic) {
      getQuestions(_filters);
    }

    // if (_filters.topic && _filters.subTopic) {
    //   setIsLoading(true);

    //   let _titleOptions = [];
    //   let _finalNotes = {};
    //   const titlesRef = firestore.collection("titles");
    //   const titlesRefSnapshot = await titlesRef.get();
    //   titlesRefSnapshot.forEach((doc) => {

    //     // _finalNotes[doc.data().title] = doc.data();
    //     _titleOptions.push(createOption(doc.data().title));
    //   });
    //   // this.setState({ titleOptions, isLoading: false });
    //   setIsLoading(false);
    //   setTitleOptions(_titleOptions);
    //   // setNotes(_finalNotes);
    // }
  };

  // const {
  //   questions,
  //   isLoading,
  //   subTopicOptions,
  //   topicOptions,
  //   examOptions,
  //   titleOptions,
  // } = this.state;

  ///=>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  const [seletedId, setSeletedId] = useState("");
  const [notesTabKey, setTabKey] = React.useState("rn");
  const preQuestion = () => {
    var keys = Object.keys(questions);
    var loc = keys.indexOf(seletedId);
    const prevItem = loc === 0 ? Object.keys(questions).length - 1 : loc - 1;

    setSeletedId(keys[prevItem]);
    setTabKey("rn");
  };

  const nextQuestion = () => {
    // const q = currentQ === qIds.length - 1 ? 0 : currentQ + 1;
    // setCurrentQuestion(q);
    var keys = Object.keys(questions);
    var loc = keys.indexOf(seletedId);
    const prevItem = loc === Object.keys(questions).length - 1 ? 0 : loc + 1;

    setSeletedId(keys[prevItem]);
    setTabKey("rn");
  };
  const [currentPage, setCurrentPage] = React.useState(0);
  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }

  const offset = currentPage * PER_PAGE;
  const currentPageData = Object.values(questions || {})
    ?.slice(offset, offset + PER_PAGE)
    .map((v, i) => (
      <li
        className={` ${
          seletedId === v.id ? "bg-light" : "border-bottom"
        }  p-1 my-2`}

        // className={`${deshBoard.includes(path) ? "mm-active" : ""}`}
      >
        <Link
          className="  d-flex align-items-center   "
          to="#"
          //   to="/"
          aria-expanded="false"
          onClick={() => setSeletedId(v.id)}
        >
          <p className=" ml-2 mb-0">{v?.title}</p>
        </Link>
      </li>
    ));

  const pageCount = Math.ceil(
    Object.values(questions || {})?.length / PER_PAGE
  );

  const nextIcon = React.createElement("i", {
    className: "mdi mdi-chevron-double-right text-info",
  });
  const prevIcon = React.createElement("i", {
    className: "mdi mdi-chevron-double-left text-info",
  });
  return (
    <Fragment>
      {/* <PageTitle activeMenu="List" motherMenu="Library" /> */}
      {userType === "demo_user" || userType === "expaird_user" ? (
        <Row>
          <Col>
            <Alert
              variant="info"
              className="alert-alt alert-dismissible fade show"
            >
              Currently you are accessing the demo version. To access the full
              version of this library you need to{" "}
              <a
                type="submit"
                className="btn btn-warning  btn-rounded btn-xxs ml-2"
                href={`/auth#pricing`}
              >
                {" "}
                Subscribe to a plan
              </a>
            </Alert>
          </Col>
        </Row>
      ) : null}
      <Row>
        <Col lg={3} md={12}>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <Select
                    // isClearable
                    isDisabled={
                      isLoading ||
                      userType === "demo_user" ||
                      userType === "expaird_user"
                    }
                    isLoading={isLoading}
                    onChange={(v) =>
                      quiestionFilter("topic", v, studyessentialQsOnly)
                    }
                    options={topicOptions}
                    placeholder="Topic"
                    // value={topic}
                    defaultValue={defaultTopic}
                  />
                  <p className="mt-2"></p>
                  {filters?.topic === "Internal Medicine" && (
                    <Select
                      // isClearable
                      isDisabled={
                        isLoading ||
                        userType === "demo_user" ||
                        userType === "expaird_user"
                      }
                      isLoading={isLoading}
                      onChange={(v) =>
                        quiestionFilter("subTopic", v, studyessentialQsOnly)
                      }
                      options={subTopicOptions}
                      placeholder="Sub Topic"
                      defaultValue={defaultSubTopic}

                      // value={this.state.subTopic}
                    />
                  )}
                </div>
                <div className="col-12">
                  {pageCount > 1 && (
                    <nav aria-label="Page navigation" className="mt-4">
                      <ReactPaginate
                        previousLabel={prevIcon}
                        nextLabel={nextIcon}
                        pageCount={pageCount}
                        onPageChange={handlePageClick}
                        containerClassName={
                          "pagination pagination-sm justify-content-center flex-wrap"
                        }
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousLinkClassName={"page-item page-link"}
                        nextLinkClassName={"page-item page-link"}
                        disabledClassName={"disabled"}
                        activeClassName={"active"}
                      />
                    </nav>
                  )}
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-12 border-top">
                  <MM className="metismenu" id="menu">
                    <PerfectScrollbar
                      style={{
                        // height: "58vh",
                        paddingRight: "1rem",
                      }}
                      id="DZ_W_Todo3"
                      className="widget-media dz-scroll  ps ps--active-y"
                    >
                      {isLoading ? (
                        <p className="text-center mt-4">
                          <i
                            class="fa fa-circle-o-notch fa-spin fa-2x fa-fw "
                            style={{ color: "#28435861" }}
                          ></i>
                          <span class="sr-only">Loading...</span>
                        </p>
                      ) : Object.values(questions || {}).map((v) => v)
                          .length === 0 ? (
                        <p className="text-center mt-4">No title to display</p>
                      ) : (
                        currentPageData
                      )}
                    </PerfectScrollbar>
                  </MM>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col lg={9} md={12}>
          <div className="card">
            <div className="card-body">
              {isLoading ? (
                <div className="d-flex align-items-center justify-content-center flex-column h-100">
                  {/* <LinearProgress variant="query" /> */}

                  <Lottie
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: loadingStethoscope,
                      rendererSettings: {
                        preserveAspectRatio: "xMidYMid slice",
                      },
                    }}
                    height={200}
                    width={200}
                    // isStopped={this.state.isStopped}
                    // isPaused={this.state.isPaused}
                  />
                  <p className="text-center mt-2">loading notes...</p>
                </div>
              ) : questions[seletedId] ? (
                <Tab.Container defaultActiveKey={"tudy notes"}>
                  <div
                    className="row mb-4"
                    // className=" d-flex align-items-center justify-content-between flex-row mb-4 border p-3 "
                  >
                    {questions[seletedId] && (
                      <>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                          <div
                            className="row mb-2"
                            // className="d-flex  align-items-center justify-content-between flex-column"
                          >
                            <div className="col-sm-8 col-md-12 col-lg-12">
                              {userType === "demo_user" ||
                              userType === "expaird_user" ? (
                                <></>
                              ) : (
                                <a
                                  style={{
                                    position: "relative",
                                  }}
                                  className=" btn btn-outline-info btn-sm btn-rounded"
                                  href={
                                    questions[seletedId]?.topic !==
                                    "internalmedicine"
                                      ? `/questions/${questions[seletedId]?.topic}?title=${questions[seletedId].title}`
                                      : `/questions/${questions[seletedId]?.topic}/${questions[seletedId]?.subTopic}/?title=${questions[seletedId].title}`
                                  }
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {" "}
                                  Revise All Questions
                                </a>
                              )}
                            </div>
                            <div className="col-sm-4 col-md-12 col-lg-12">
                              <p className="mb-0">
                                Total Questions{" "}
                                {questions[seletedId]?.questions_count}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                          <div className="question-header    ">
                            <button
                              type="button"
                              onClick={preQuestion}
                              class="btn btn-outline-dark btn-rounded btn-xxs d-flex align-items-center justify-content-center"
                            >
                              <i class="las la-angle-left la-lg"></i>
                            </button>

                            <div className="spacer" />
                            <h5 className="m-0 mx-3">
                              {Object.keys(questions || {})?.indexOf(
                                seletedId
                              ) + 1}
                              /{Object.keys(questions || {})?.length}
                            </h5>
                            <div className="spacer" />

                            <button
                              type="button"
                              onClick={nextQuestion}
                              class="btn btn-outline-dark btn-rounded btn-xxs d-flex align-items-center justify-content-center"
                            >
                              <i class="las la-angle-right la-lg"></i>
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <Tabs
                    className="nav   mb-2 "
                    id="controlled-tab-example "
                    activeKey={notesTabKey}
                    onSelect={(k) => setTabKey(k)}
                  >
                    {questions[seletedId]?.revisionNotes?.replace(/\s/g, "") !==
                      "" && (
                      <Tab
                        eventKey="rn"
                        title="Revision Notes"
                        className="border p-4 rounded mb-2"
                      >
                        <>
                          <span
                            style={{
                              position: "absolute",
                              left: "10%",
                              top: "40%",
                              textAalign: "center",
                              // zIndex: "999",
                              color: "#eee",
                              display: "grid",
                              opacity: "0.2",
                            }}
                          >
                            <img
                              src={logo}
                              style={{ opacity: "0.2", maxWidth: "100px" }}
                            />
                            {authUser.email}
                          </span>
                          <span
                            style={{
                              position: "absolute",
                              left: "10%",
                              bottom: "20%",
                              textAalign: "center",
                              // zIndex: "999",
                              color: "#eee",
                              display: "grid",
                              opacity: "0.2",
                            }}
                          >
                            <img
                              src={logo}
                              style={{ opacity: "0.2", maxWidth: "100px" }}
                            />
                            {authUser.email}
                          </span>
                          <span
                            style={{
                              position: "absolute",
                              right: "30%",
                              top: "30%",
                              textAalign: "center",
                              // zIndex: "999",
                              color: "#eee",
                              display: "grid",
                              opacity: "0.2",
                            }}
                          >
                            <img
                              src={logo}
                              style={{ opacity: "0.2", maxWidth: "100px" }}
                            />
                            {authUser.email}
                          </span>
                          <span
                            style={{
                              position: "absolute",
                              right: "40%",
                              top: "40%",
                              textAalign: "center",
                              // zIndex: "999",
                              color: "#eee",
                              display: "grid",
                              opacity: "0.2",
                            }}
                          >
                            <img
                              src={logo}
                              style={{ opacity: "0.2", maxWidth: "100px" }}
                            />
                            {authUser.email}
                          </span>
                          <span
                            style={{
                              position: "absolute",
                              right: "10%",
                              bottom: "10%",
                              textAalign: "center",
                              // zIndex: "999",
                              color: "#eee",
                              display: "grid",
                              opacity: "0.2",
                            }}
                          >
                            <img
                              src={logo}
                              style={{ opacity: "0.2", maxWidth: "100px" }}
                            />
                            {authUser.email}
                          </span>
                          <span
                            style={{
                              position: "absolute",
                              right: "30%",
                              bottom: "15%",
                              textAalign: "center",
                              // zIndex: "999",
                              color: "#eee",
                              display: "grid",
                              opacity: "0.2",
                            }}
                          >
                            <img
                              src={logo}
                              style={{ opacity: "0.2", maxWidth: "100px" }}
                            />
                            {authUser.email}
                          </span>

                          <span
                            style={{
                              position: "absolute",
                              right: "38%",
                              bottom: "18%",
                              textAalign: "center",
                              // zIndex: "999",
                              color: "#eee",
                              display: "grid",
                              opacity: "0.2",
                            }}
                          >
                            <img
                              src={logo}
                              style={{ opacity: "0.2", maxWidth: "100px" }}
                            />
                            {authUser.email}
                          </span>

                          {/*<TinyView
                              value={questions[seletedId]?.revisionNotes}
                            />*/}
                          <div
                            className="notes-questions"
                            dangerouslySetInnerHTML={{
                              __html: questions[
                                seletedId
                              ]?.revisionNotes.replace(
                                /(<table\b[^>]*\bstyle=["'][^"']*)\bwidth\s*:\s*[^;]+;?/gi,
                                "$1"
                              ),
                            }}
                          />
                        </>
                      </Tab>
                    )}
                    {questions[seletedId]?._description?.replace(/\s/g, "") !==
                      "" && (
                      <Tab
                        eventKey="sn"
                        title="Study Notes"
                        className="border p-4 rounded mb-2"
                      >
                        <>
                          <span
                            style={{
                              position: "absolute",
                              left: "10%",
                              top: "40%",
                              textAalign: "center",
                              // zIndex: "999",
                              color: "#eee",
                              display: "grid",
                              opacity: "0.2",
                            }}
                          >
                            <img
                              src={logo}
                              style={{ opacity: "0.2", maxWidth: "100px" }}
                            />
                            {authUser.email}
                          </span>
                          <span
                            style={{
                              position: "absolute",
                              left: "10%",
                              bottom: "20%",
                              textAalign: "center",
                              // zIndex: "999",
                              color: "#eee",
                              display: "grid",
                              opacity: "0.2",
                            }}
                          >
                            <img
                              src={logo}
                              style={{ opacity: "0.2", maxWidth: "100px" }}
                            />
                            {authUser.email}
                          </span>
                          <span
                            style={{
                              position: "absolute",
                              right: "30%",
                              top: "30%",
                              textAalign: "center",
                              // zIndex: "999",
                              color: "#eee",
                              display: "grid",
                              opacity: "0.2",
                            }}
                          >
                            <img
                              src={logo}
                              style={{ opacity: "0.2", maxWidth: "100px" }}
                            />
                            {authUser.email}
                          </span>
                          <span
                            style={{
                              position: "absolute",
                              right: "40%",
                              top: "40%",
                              textAalign: "center",
                              // zIndex: "999",
                              color: "#eee",
                              display: "grid",
                              opacity: "0.2",
                            }}
                          >
                            <img
                              src={logo}
                              style={{ opacity: "0.2", maxWidth: "100px" }}
                            />
                            {authUser.email}
                          </span>
                          <span
                            style={{
                              position: "absolute",
                              right: "10%",
                              bottom: "10%",
                              textAalign: "center",
                              // zIndex: "999",
                              color: "#eee",
                              display: "grid",
                              opacity: "0.2",
                            }}
                          >
                            <img
                              src={logo}
                              style={{ opacity: "0.2", maxWidth: "100px" }}
                            />
                            {authUser.email}
                          </span>
                          <span
                            style={{
                              position: "absolute",
                              right: "30%",
                              bottom: "15%",
                              textAalign: "center",
                              // zIndex: "999",
                              color: "#eee",
                              display: "grid",
                              opacity: "0.2",
                            }}
                          >
                            <img
                              src={logo}
                              style={{ opacity: "0.2", maxWidth: "100px" }}
                            />
                            {authUser.email}
                          </span>

                          <span
                            style={{
                              position: "absolute",
                              right: "38%",
                              bottom: "18%",
                              textAalign: "center",
                              // zIndex: "999",
                              color: "#eee",
                              display: "grid",
                              opacity: "0.2",
                            }}
                          >
                            <img
                              src={logo}
                              style={{ opacity: "0.2", maxWidth: "100px" }}
                            />
                            {authUser.email}
                          </span>

                          {/*  <TinyView
                              value={questions[seletedId]?._description}
                            />*/}
                          <div
                            className="notes-questions"
                            dangerouslySetInnerHTML={{
                              __html: questions[seletedId]?._description,
                            }}
                          />
                        </>
                      </Tab>
                    )}

                    {/* {questions[seletedId]?.explanationNotes?.replace(
                      /\s/g,
                      ""
                    ) !== "" && (
                      <Tab eventKey="en" title="Explanation Notes">
                        <>
                          <TinyView  <div className="mb-4">
                    {questions[seletedId] && (
                      <ol
                        className="breadcrumb"
                        style={{ borderRadius: "0px" }}
                      >
                        <li className="breadcrumb-item">
                          <Link> {questions[seletedId]?.topic}</Link>
                        </li>
                        <li className="breadcrumb-item active">
                          <Link>{questions[seletedId]?.subTopic}</Link>
                        </li>
                      </ol>
                    )}
                  </div>
                            value={questions[seletedId]?.explanationNotes}
                          />
                        </>
                      </Tab>
                    )} */}
                  </Tabs>
                  <div
                    className="row mb-4"
                    // className=" d-flex align-items-center justify-content-between flex-row mb-4 border p-3 "
                  >
                    {questions[seletedId] && (
                      <>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                          <div
                            className="row mb-2"
                            // className="d-flex  align-items-center justify-content-between flex-column"
                          >
                            <div className="col-sm-8 col-md-12 col-lg-12">
                              {userType === "demo_user" ||
                              userType === "expaird_user" ? (
                                <></>
                              ) : (
                                <a
                                  style={{
                                    position: "relative",
                                  }}
                                  className=" btn btn-outline-info btn-sm btn-rounded"
                                  href={
                                    questions[seletedId]?.topic !==
                                    "internalmedicine"
                                      ? `/questions/${questions[seletedId]?.topic}?title=${questions[seletedId].title}`
                                      : `/questions/${questions[seletedId]?.topic}/${questions[seletedId]?.subTopic}/?title=${questions[seletedId].title}`
                                  }
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {" "}
                                  Revise All Questions
                                </a>
                              )}
                            </div>
                            <div className="col-sm-4 col-md-12 col-lg-12">
                              <p className="mb-0">
                                Total Questions{" "}
                                {questions[seletedId]?.questions_count}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                          <div className="question-header    ">
                            <button
                              type="button"
                              onClick={preQuestion}
                              class="btn btn-outline-dark btn-rounded btn-xxs d-flex align-items-center justify-content-center"
                            >
                              <i class="las la-angle-left la-lg"></i>
                            </button>

                            <div className="spacer" />
                            <h5 className="m-0 mx-3">
                              {Object.keys(questions || {})?.indexOf(
                                seletedId
                              ) + 1}
                              /{Object.keys(questions || {})?.length}
                            </h5>
                            <div className="spacer" />

                            <button
                              type="button"
                              onClick={nextQuestion}
                              class="btn btn-outline-dark btn-rounded btn-xxs d-flex align-items-center justify-content-center"
                            >
                              <i class="las la-angle-right la-lg"></i>
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <Alert variant="warning" className="alert-alt  fade show">
                    This content is protected under International and Local
                    Copyrights law of respective countries. Any unauthorized
                    distribution of content published herein any means or format
                    is a punishable offence under Courts of Law.
                  </Alert>
                  <div className="border p-4 mb-4 rounded">
                    <div className="">
                      <h4 className="card-title card-intro-title mb-4">
                        Helpful Links
                      </h4>

                      <>
                        {questions[seletedId].helpfulLinks?.map((item, k) => (
                          <span key={k}>
                            {item?.link?.match(/^http/) ? (
                              <a href={item.link} target="_blank">
                                {" "}
                                {item.reason}
                              </a>
                            ) : (
                              <div className="iframe-container">
                                <span
                                  className="question-text"
                                  dangerouslySetInnerHTML={{
                                    __html: item.link,
                                  }}
                                />
                              </div>
                            )}
                            <br />
                          </span>
                        ))}
                      </>
                    </div>
                  </div>
                </Tab.Container>
              ) : (
                <div className="d-flex align-items-center justify-content-center h-75">
                  <p> No Tilte selected.</p>
                </div>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Library;
