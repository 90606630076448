import React from "react";
import {
  Col,
  Badge,
  Button,
  Row,
  Accordion,
  Card,
  Tabs,
  Tab,
  Nav,
  Pagination,
  Container,
  Alert,
} from "react-bootstrap";
import TinyView from "../../../components/Editor/tiny";
import logo from "../../../../images/m-logo-text.png";
export default function RenderNotes({
  _description,
  revisionNotes,
  explanationNotes,
  notesTabKey,
  setTabKey,
  setSuggestionmodal,
  authUser,
}) {
  return (
    <>
      <Row className="disable-text-selection">
        <Col>
          <div className="card">
            <span
              style={{
                position: "absolute",
                left: "10%",
                top: "40%",
                textAalign: "center",
                // zIndex: "999",
                color: "#eee",
                display: "grid",
                opacity: "0.2",
              }}
            >
              <img src={logo} style={{ opacity: "0.2", maxWidth: "100px" }} />
              {authUser.email}
            </span>
            <span
              style={{
                position: "absolute",
                left: "10%",
                bottom: "20%",
                textAalign: "center",
                // zIndex: "999",
                color: "#eee",
                display: "grid",
                opacity: "0.2",
              }}
            >
              <img src={logo} style={{ opacity: "0.2", maxWidth: "100px" }} />
              {authUser.email}
            </span>
            <span
              style={{
                position: "absolute",
                right: "30%",
                top: "30%",
                textAalign: "center",
                // zIndex: "999",
                color: "#eee",
                display: "grid",
                opacity: "0.2",
              }}
            >
              <img src={logo} style={{ opacity: "0.2", maxWidth: "100px" }} />
              {authUser.email}
            </span>
            <span
              style={{
                position: "absolute",
                right: "40%",
                top: "40%",
                textAalign: "center",
                // zIndex: "999",
                color: "#eee",
                display: "grid",
                opacity: "0.2",
              }}
            >
              <img src={logo} style={{ opacity: "0.2", maxWidth: "100px" }} />
              {authUser.email}
            </span>
            <span
              style={{
                position: "absolute",
                right: "10%",
                bottom: "10%",
                textAalign: "center",
                // zIndex: "999",
                color: "#eee",
                display: "grid",
                opacity: "0.2",
              }}
            >
              <img src={logo} style={{ opacity: "0.2", maxWidth: "100px" }} />
              {authUser.email}
            </span>
            <span
              style={{
                position: "absolute",
                right: "30%",
                bottom: "15%",
                textAalign: "center",
                // zIndex: "999",
                color: "#eee",
                display: "grid",
                opacity: "0.2",
              }}
            >
              <img src={logo} style={{ opacity: "0.2", maxWidth: "100px" }} />
              {authUser.email}
            </span>

            <span
              style={{
                position: "absolute",
                right: "38%",
                bottom: "18%",
                textAalign: "center",
                // zIndex: "999",
                color: "#eee",
                display: "grid",
                opacity: "0.2",
              }}
            >
              <img src={logo} style={{ opacity: "0.2", maxWidth: "100px" }} />
              {authUser.email}
            </span>

            <div className="card-body">
              <div className="d-flex align-items-center justify-content-end">
                {/* <h4 className="card-title card-intro-title mb-4">Notes</h4> */}
                <button
                  onClick={() => setSuggestionmodal(true)}
                  className="btn btn-outline-success btn-sm btn-rounded border d-flex align-items-center "
                >
                  <i class="las la-feather la-lg mr-1"></i>
                  Suggest Key Points
                </button>
              </div>
              <Tabs
                className="nav mb-2 "
                id="controlled-tab-example"
                activeKey={notesTabKey}
                onSelect={(k) => setTabKey(k)}
              >
                {revisionNotes && revisionNotes.replace(/\s/g, "") !== "" && (
                  <Tab eventKey="rn" title="Revision Notes">
                    <>
                      {/* <TinyView value={revisionNotes} />*/}
                      <div
                        className="notes-questions"
                        dangerouslySetInnerHTML={{
                          __html: revisionNotes.replace(
                            /font-family:\s*helvetica,\s*arial,\s*sans-serif;/gi,
                            "font-family: 'Sofia Sans', system-ui;"
                          ),
                        }}
                      />
                    </>
                  </Tab>
                )}
                {_description && _description.replace(/\s/g, "") !== "" && (
                  <Tab eventKey="sn" title="Study Notes">
                    <>
                      {/*   <TinyView value={_description} />*/}
                      <div
                        className="notes-questions"
                        dangerouslySetInnerHTML={{
                          __html: _description
                            .replace(
                              /font-family:\s*helvetica,\s*arial,\s*sans-serif;/gi,
                              "font-family: 'Sofia Sans', system-ui;"
                            )
                            .replace(
                              /(<table\b[^>]*\bstyle=["'][^"']*)\bwidth\s*:\s*[^;]+;?/gi,
                              "$1"
                            ),
                        }}
                      />
                    </>
                  </Tab>
                )}

                {explanationNotes &&
                  explanationNotes.replace(/\s/g, "") !== "" && (
                    <Tab eventKey="en" title="Explanation Notes">
                      <>
                        {/*  <TinyView value={explanationNotes} />*/}
                        <div
                          className="notes-questions"
                          dangerouslySetInnerHTML={{
                            __html: explanationNotes.replace(
                              /font-family:\s*helvetica,\s*arial,\s*sans-serif;/gi,
                              "font-family: 'Sofia Sans', system-ui;"
                            ),
                          }}
                        />
                      </>
                    </Tab>
                  )}
              </Tabs>

              {/* <Tab.Container defaultActiveKey={key}>
              <Nav as="ul" className="nav-pills mb-2 ">
                {_description && _description.replace(/\s/g, "") !== "" && (
                  <Nav.Item as="li">
                    <Nav.Link eventKey={"sn"} className="border border-primary">
                      Study Notes
                    </Nav.Link>
                  </Nav.Item>
                )}
                {revisionNotes && revisionNotes.replace(/\s/g, "") !== "" && (
                  <Nav.Item as="li">
                    <Nav.Link eventKey={"rn"} className="border border-primary">
                      Revision Notes
                    </Nav.Link>
                  </Nav.Item>
                )}
                {explanationNotes &&
                  explanationNotes.replace(/\s/g, "") !== "" && (
                    <Nav.Item as="li">
                      <Nav.Link
                        eventKey={"en"}
                        className="border border-primary"
                      >
                        Explanation Notes
                      </Nav.Link>
                    </Nav.Item>
                  )}
              </Nav>
              <Tab.Content className="pt-4 ">
                {_description && _description.replace(/\s/g, "") !== "" && (
                  <Tab.Pane eventKey={"sn"}>
                    <>
                      <TinyView value={_description} />
                    </>
                  </Tab.Pane>
                )}
                {revisionNotes && revisionNotes.replace(/\s/g, "") !== "" && (
                  <Tab.Pane eventKey={"rn"}>
                    <>
                      <TinyView value={revisionNotes} />
                    </>
                  </Tab.Pane>
                )}
                {explanationNotes &&
                  explanationNotes.replace(/\s/g, "") !== "" && (
                    <Tab.Pane eventKey={"en"}>
                      <>
                        <TinyView value={explanationNotes} />
                      </>
                    </Tab.Pane>
                  )}
              </Tab.Content>
            </Tab.Container>
        */}
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Alert variant="warning" className="alert-alt  fade show">
            This content is protected under International and Local Copyrights
            law of respective countries. Any unauthorized distribution of
            content published herein any means or format is a punishable offence
            under Courts of Law.
          </Alert>
        </Col>
      </Row>
    </>
  );
}
