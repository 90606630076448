import React, { Fragment, useState, useEffect, useContext } from "react";
import { firebase, firestore } from "../../../firebase";
import AuthContext from "../../../context";
import { Col, Row, Tabs, Tab, Alert } from "react-bootstrap";
import MetisMenu from "metismenujs";
import { Link } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import Select from "react-select";
import ReactPaginate from "react-paginate";
import Lottie from "react-lottie";
import loadingStethoscope from "../../56363-stethoscope-doctor.json";
import swal from "sweetalert";
import zIndex from "@material-ui/core/styles/zIndex";

class MM extends React.Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }
  componentWillUnmount() {
    //  this.mm("dispose");
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const Tags = () => {
  const authUser = firebase?.auth()?.currentUser;
  const { userType } = useContext(AuthContext);
  const [tags, setTags] = useState([]);
  const [alltags, setAlltags] = useState([]);
  const [selectedTag, setSelectedTag] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [studyessentialQsOnly, setStudyessentialQsOnly] = useState(false);
  const [filters, setFilters] = useState({});
  const [questions, setQuestions] = useState({});
  const [topics, setTopics] = useState([]);

  const PER_PAGE = 15;
  const defaultTag = {
    label: "All",
    value: "",
  };

  const tagOptions = [
    {
      label: "All",
      value: "",
    },
    {
      label: "Condition",
      value: "condition",
    },
    {
      label: "Presentations",
      value: "presentations",
    },
  ];

  const createOption = (label, value) => ({
    label,
    value: value,
  });

  useEffect(() => {
    if (userType !== "") {
      if (userType !== "demo_user" || userType === "expaird_user") {
        firebase.auth().onAuthStateChanged(async (user) => {
          if (user) {
            const userSnap = await firestore
              .collection("users")
              .doc(user.uid)
              .get();

            setStudyessentialQsOnly(
              userSnap.data().studyessentialQsOnly || false
            );
            getTags("");
          }
        });
      }
    }
  }, []);

  const getTags = async (type) => {
    setIsLoading(true);
    let conditions = [];
    if (type == "presentations") {
      conditions = ["presentations"];
    } else if (type == "condition") {
      conditions = ["condition"];
    } else {
      conditions = ["presentations", "condition"];
    }

    const tagsSnapshot = await firestore
      .collection("tags")
      .where("type", "in", conditions)
      .get();

    let alltags = [];

    tagsSnapshot.forEach((doc) => {
      alltags.push({
        id: doc.id,
        name: doc.data().name,
        type: doc.data().type,
      });
    });

    /* alltags.sort(function (a, b) {
      return a.name.toLowerCase() > b.name.toLowerCase();
    }); */

    alltags.sort((a, b) => {
      let fa = a.name.toLowerCase(),
        fb = b.name.toLowerCase();

      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });

    setTags(alltags);
    setAlltags(alltags);
    setIsLoading(false);
    setSeletedId(alltags[0].id);
    setSelectedTag(alltags[0].name);
    quiestionFilter(alltags[0].id, studyessentialQsOnly);
  };

  const [currentPage, setCurrentPage] = React.useState(0);
  const [seletedId, setSeletedId] = useState("");
  const [notesTabKey, setTabKey] = React.useState("rn");
  const offset = currentPage * PER_PAGE;

  const preQuestion = () => {
    var keys = Object.keys(questions);
    var loc = keys.indexOf(seletedId);
    const prevItem = loc === 0 ? Object.keys(questions).length - 1 : loc - 1;

    setSeletedId(keys[prevItem]);
    setTabKey("rn");
  };

  const nextQuestion = () => {
    // const q = currentQ === qIds.length - 1 ? 0 : currentQ + 1;
    // setCurrentQuestion(q);
    var keys = Object.keys(questions);
    var loc = keys.indexOf(seletedId);
    const prevItem = loc === Object.keys(questions).length - 1 ? 0 : loc + 1;

    setSeletedId(keys[prevItem]);
    setTabKey("rn");
  };

  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }

  const selectTag = (v) => {
    setSeletedId(v.id);
    setSelectedTag(v.name);
    quiestionFilter(v.id, studyessentialQsOnly);
  };

  const currentPageData = Object.values(tags || {})
    ?.slice(offset, offset + PER_PAGE)
    .map((v, i) => (
      <li
        className={` ${
          seletedId === v.id ? "bg-light" : "border-bottom"
        }  p-1 my-2`}
        // className={`${deshBoard.includes(path) ? "mm-active" : ""}`}
        key={i}
      >
        <Link
          className="  d-flex align-items-center   "
          to="#"
          //   to="/"
          aria-expanded="false"
          onClick={() => selectTag(v)}
        >
          <p className=" ml-2 mb-0">{v?.name}</p>
        </Link>
      </li>
    ));

  const pageCount = Math.ceil(Object.values(tags || {})?.length / PER_PAGE);

  const nextIcon = React.createElement("i", {
    className: "mdi mdi-chevron-double-right text-info",
  });
  const prevIcon = React.createElement("i", {
    className: "mdi mdi-chevron-double-left text-info",
  });

  const quiestionFilter = async (value, se) => {
    const _filters = Object.assign({}, filters);
    if (value) {
      _filters["tags"] = value;
    } else {
      _filters["tags"] = null;
    }

    if (se) {
      _filters["studyessentialQuestion"] = true;
    } else {
      _filters["studyessentialQuestion"] = false;
    }

    setFilters(_filters);
    getQuestions(_filters);
  };

  const questionsRef = (filters) => {
    let ref = firestore.collection("questions");
    Object.keys(filters || {}).forEach((filter) => {
      if (filters[filter]) {
        if (filter == "tags") {
          ref = ref.where(filter, "array-contains", filters[filter]);
        } else {
          ref = ref.where(filter, "==", filters[filter]);
        }
      }
    });
    return ref;
  };

  const setSearch = (text) => {
    const txt = text.toLowerCase();
    const stags = alltags.filter(function (item) {
      //return item.name.toLowerCase().includes(txt);
      return item.name.toLowerCase().startsWith(txt);
    });
    setTags(stags);
  };

  const getQuestions = async (filters) => {
    try {
      setIsLoading(true);

      let questions = {};
      const querySnapshot = await questionsRef(filters).get();
      querySnapshot.forEach((doc) => {
        // let questions_count = 0;
        const data = doc.data();
        // if (
        //   Object.keys(questions).some((v) => questions[v].title === data.title)
        // ) {
        //   questions_count++;
        // }
        const qus_id = Object.keys(questions).find(
          (v) => questions[v].title === data.title
        );

        if (!qus_id) {
          questions[doc.id] = {
            // pastPaperQuestion: data.pastPaperQuestion,
            id: doc.id,
            _description: data?._description,
            revisionNotes: data?.revisionNotes.replace(
              /(<table\b[^>]*\bstyle=["'][^"']*)\bwidth\s*:\s*[^;]+;?/gi,
              "$1"
            ),
            // exam: data.exam,
            title: data.title,
            topic: data.topicId,
            helpfulLinks: data?.helpfulLinks,
            // publish: data.publish ? "Yes" : "No",
            subTopic: data.subTopicId,
            questions_count: 1,
            // answerCount: Object.keys(data.answers).length,
          };
        } else {
          questions[qus_id].questions_count =
            questions[qus_id]?.questions_count + 1;
        }
      });

      setIsLoading(false);
      setQuestions(questions);
      let keys = Object.keys(questions);
      setTopics(Object.values(questions));
      //setSeletedId(keys[0]);
    } catch (error) {
      console.error("error=>", error);
      setIsLoading(false);
    }
    // this.setState({
    //   questions,
    //   isLoading: false
    // });
  };

  return (
    <Fragment>
      {userType === "demo_user" || userType === "expaird_user" ? (
        <Row>
          <Col>
            <Alert
              variant="info"
              className="alert-alt alert-dismissible fade show"
            >
              Currently you are accessing the demo version. To access the full
              version of this library you need to{" "}
              <a
                type="submit"
                className="btn btn-warning  btn-rounded btn-xxs ml-2"
                href={`/auth#pricing`}
              >
                {" "}
                Subscribe to a plan
              </a>
            </Alert>
          </Col>
        </Row>
      ) : null}

      <Row>
        <Col lg={3} md={12}>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <div className="input-group search-area d-inline-flex mb-3">
                    <div className="input-group-append">
                      <span
                        className="input-group-text"
                        style={{ borderRadius: "20px 0px 0px 20px" }}
                      >
                        <i className="flaticon-381-search-2" />
                      </span>
                    </div>
                    <input
                      style={{
                        borderRadius: "0 20px 20px 0",
                        height: "46px",
                        zIndex: 0,
                      }}
                      onChange={({ target }) => setSearch(target.value)}
                      type="text"
                      className="form-control"
                      placeholder="Search here"
                    />
                  </div>
                  <Select
                    // isClearable
                    isDisabled={
                      isLoading ||
                      userType === "demo_user" ||
                      userType === "expaird_user"
                    }
                    isLoading={isLoading}
                    onChange={(v) => getTags(v.value)}
                    options={tagOptions}
                    placeholder="Tags"
                    // value={topic}
                    defaultValue={defaultTag}
                    style={{ zIndex: 2 }}
                  />
                </div>
                <div className="col-12">
                  {pageCount > 1 && (
                    <nav aria-label="Page navigation" className="mt-4">
                      <ReactPaginate
                        previousLabel={prevIcon}
                        nextLabel={nextIcon}
                        pageCount={pageCount}
                        onPageChange={handlePageClick}
                        containerClassName={
                          "pagination pagination-sm justify-content-center flex-wrap"
                        }
                        pageClassName="page-item page-index"
                        pageLinkClassName="page-link"
                        previousLinkClassName={"page-item page-link"}
                        nextLinkClassName={"page-item page-link"}
                        disabledClassName={"disabled"}
                        activeClassName={"active"}
                      />
                    </nav>
                  )}
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-12 border-top">
                  <MM className="metismenu" id="menu">
                    <PerfectScrollbar
                      style={{
                        // height: "58vh",
                        paddingRight: "1rem",
                      }}
                      id="DZ_W_Todo3"
                      className="widget-media dz-scroll  ps ps--active-y"
                    >
                      {isLoading ? (
                        <p className="text-center mt-4">
                          <i
                            class="fa fa-circle-o-notch fa-spin fa-2x fa-fw "
                            style={{ color: "#28435861" }}
                          ></i>
                          <span class="sr-only">Loading...</span>
                        </p>
                      ) : Object.values(tags || {}).map((v) => v).length ===
                        0 ? (
                        <p className="text-center mt-4">No title to display</p>
                      ) : (
                        currentPageData
                      )}
                    </PerfectScrollbar>
                  </MM>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col lg={9} md={12}>
          <div className="card">
            <div className="card-body">
              {isLoading ? (
                <div className="d-flex align-items-center justify-content-center flex-column h-100">
                  <Lottie
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: loadingStethoscope,
                      rendererSettings: {
                        preserveAspectRatio: "xMidYMid slice",
                      },
                    }}
                    height={200}
                    width={200}
                    // isStopped={this.state.isStopped}
                    // isPaused={this.state.isPaused}
                  />
                  <p className="text-center mt-2">loading titles...</p>
                </div>
              ) : questions ? (
                <>
                  <div className="d-flex align-items-center justify-content-between p-2 border-bottom  my-2">
                    <h5 className="">{selectedTag}</h5>
                    <button
                      type="button"
                      class="btn btn-xs mb-0 btn-info tp-btn-light"
                      onClick={() =>
                        swal({
                          title: "What do you want to revise ?",

                          buttons: {
                            notes: {
                              text: "Notes",
                              value: "notes",
                              className: "bg-dark",
                            },
                            questions: {
                              text: "Questions",
                              value: "questions",
                              className: "bg-info",
                            },
                          },
                        }).then((v) => {
                          if (v === "questions") {
                            window.location = `/tag?show=questions&tag=${seletedId}`;
                          } else if (v === "notes") {
                            window.location = `/tag?show=notes&tag=${seletedId}`;
                          }
                        })
                      }
                    >
                      Revise
                    </button>
                  </div>
                  <div className="row no-gutters ">
                    {topics.map((e, i) => (
                      <div key={i} className="col-auto  m-2" onClick={() => {}}>
                        <span
                          className={`badge badge-outline-info badge-rounded d-flex align-items-center`}
                          style={{
                            fontWeight: "500",
                            cursor: "cursor",
                          }}
                        >
                          {e.title}
                        </span>
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <div className="d-flex align-items-center justify-content-center h-75">
                  <p> No Tag selected.</p>
                </div>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Tags;
