// import "./index.scss";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Snackbar,
  Tooltip,
  Typography,
} from "@material-ui/core";

import React, { useEffect, useState, Fragment } from "react";
import { firebase, firestore } from "../../../firebase";

import CloseIcon from "@material-ui/icons/Close";
import ExamModal from "./ExamModal";

import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

import ReportModal from "./ReportModal";
import SuggestionModal from "./SuggestionModal";

import draftToHtml from "draftjs-to-html";
import { getRevisionQuestions } from "../../../util";

import RenderHelpfullLinks from "./utils/RenderHelpfullLinks";
import RenderQuestions from "./utils/RenderQuestions";
import RenderNotes from "./utils/RenderNotes";
import axios from "axios";

import PropTypes from "prop-types";

import { makeStyles, useTheme } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";

import { Col, Button, Row, Container } from "react-bootstrap";
import ChatBox from "./ChatBox";
// import PageTitle from "../../layouts/PageTitle";

import { useHistory } from "react-router-dom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

// function a11yProps(index) {
//   return {
//     id: `full-width-tab-${index}`,
//     "aria-controls": `full-width-tabpanel-${index}`,
//   };
// }

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    // width: 500,
    marginBottom: "1.5rem",
  },
  correct: {
    backgroundColor: "#c8e6c9",
    padding: "0 10px",
    borderRadius: "3px",
  },
  wrong: {
    backgroundColor: "#ffcdd2",
    padding: "0 10px",
    borderRadius: "3px",
  },
  info: {
    backgroundColor: "#b3e5fc63",
    padding: "0 10px",
    borderRadius: "3px",
  },
}));
const PER_PAGE = 26;
const Questions = (props) => {
  const { match } = props;
  const urlParams = new URLSearchParams(props.location.search);
  const myParam = urlParams.get("show");

  const [qIds, setQIds] = useState([]);
  const [allQuestions, setAllQuestions] = useState({});
  const [fullQuestions, setFullQuestions] = useState({});
  const [pastPaperQuestions, setPastPaperQuestions] = useState({});
  const [studyessentialQuestion, setStudyessentialQuestion] = useState({});
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [submittedAnswer, setSubmittedAnswer] = useState(null);
  const [currentQ, setCurrentQ] = useState(null);
  const [answerSubmitted, setAnswerSubmitted] = useState(false);
  const [submittingAnswer, setSubmittingAnswer] = useState(false);
  const [submissionPercent, setSubmissionPercent] = useState({});
  const [exam, setExam] = useState(null);
  const [mock, setMock] = useState(null);
  const [submissions, setSubmissions] = useState({});
  const [showPercents, setShowPercents] = useState(true);
  const [loading, setLoading] = useState(true);
  const [bookmarking, setBookmarking] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [expiredExam, setExpiredExam] = useState();
  const [examSeconds, setExamSeconds] = useState(0);
  const [bookmarks, setBookmarks] = useState({});
  const [examMin, setExamMin] = useState(0);
  const [reportmodal, setReportmodal] = useState(false);
  const [suggestionmodal, setSuggestionmodal] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [smartRevisionSet, setSmartRevisionSet] = React.useState({});
  const [pastPaperQsOnly, setPastPaperQsOnly] = React.useState(false);
  const [studyessentialQsOnly, setStudyessentialQsOnly] = React.useState(false);
  const [snackBar, setSnackBar] = React.useState({
    open: false,
    type: "",
    msg: "",
  });
  const [currentPage, setCurrentPage] = React.useState(0);
  function handlePageClick({ selected: selectedPage }) {
    setRenderAll(true);
    setShowDescription(true);
    setCurrentPage(selectedPage);
  }
  const [endedMock, setEndedMock] = React.useState(false);
  const [pausedMock, setPausedMock] = React.useState(false);
  const [mockLevel, setMockLevel] = React.useState(1);
  const [sub, setSub] = React.useState("");
  const [mockExamSeconds, setMockExamSeconds] = React.useState(0);
  const [mockExamMin, setMockExamMin] = React.useState(0);
  const [mockModal, setMockModal] = React.useState(true);
  const [mockUsedSec, setMockUsedSec] = React.useState(0);
  const [mockCorrect, setMockCorrect] = React.useState(0);
  const [mockSubmitted, setmockSubmitted] = React.useState(0);
  const [mockPass, setMockPass] = useState(0);
  const [tagNotes, setTagNotes] = useState([]);
  const [tagNotecurrentQ, setTagNotecurrentQ] = useState(0);

  var mockExamTimer = null;
  var timeElapsed = 0;

  // const offset = currentPage * PER_PAGE;

  // const currentPageData = qIds
  //   ?.slice(offset, offset + PER_PAGE)
  //   .map((id, i) => (
  //     <div key={i} class="col-auto m-2 ">
  //       <div class="mx-auto ">
  //         <div
  //           // className=""
  //           className={`question-ids ${
  //             currentQ === i && "question-ids-selected"
  //           }`}
  //           style={{
  //             ...(submissions[id] &&
  //               submissions[id]?.correct &&
  //               !match.path.match(/exam/) && {
  //                 background: "#d8f3d9e0",
  //               }),
  //             ...(submissions[id] &&
  //               submissions[id]?.correct === false &&
  //               !match.path.match(/exam/) && {
  //                 background: "#ffcdd2",
  //               }),
  //           }}
  //           onClick={() => {
  //             setCurrentQuestion(i + offset);
  //           }}
  //         >
  //           {i === 1 && <div class="ribbon">New! </div>}
  //           {!match.path.match(/exam/) && pastPaperQuestions[id] ? (
  //             <Tooltip
  //               title="This is a high yield or a recall question."
  //               style={{
  //                 position: "absolute",
  //                 top: "-10px",
  //               }}
  //             >
  //               {/* <StarsIcon  fontSize="small" /> */}
  //               <span>
  //                 <i class="ti-star" style={{ color: "goldenrod" }}></i>
  //               </span>
  //             </Tooltip>
  //           ) : null}
  //           <div className="d-flex flex-column align-items-center justify-content-center ">
  //             {match.path.match(/exam/) && submissions[id] && (
  //               <span
  //                 style={{
  //                   position: "absolute",
  //                 }}
  //               >
  //                 <i
  //                   style={{ color: "#a4a5a9a1" }}
  //                   class="las la-slash la-2x"
  //                 ></i>
  //               </span>
  //             )}
  //             <span>{i + offset + 1}</span>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   ));

  // const pageCount = Math.ceil(qIds?.length / PER_PAGE);

  let keyMap = {};

  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  window.document.addEventListener("keydown", function (e) {
    if (
      (e.keyCode === 44 ||
        e.keyCode === 51 ||
        e.keyCode === 52 ||
        e.keyCode === 83 ||
        e.keyCode === 115) &&
      keyMap[17]
    ) {
      alert(
        "Screen prints or any form of screen capture violates the terms and conditions of Medrevisions.com"
      );
      window.location.reload();
      return false;
    } else if (e.keyCode === 17) {
      keyMap[17] = true;
    } else {
      let keyMap = {};
    }
  });
  document.addEventListener("copy", function (e) {
    e.clipboardData.setData("text/plain", "");
    e.clipboardData.setData("text/html", "");
    e.preventDefault();
  });
  /** TO DISABLE SCREEN CAPTURE **/
  document.addEventListener("keyup", (e) => {
    if (e.key == "PrintScreen") {
      navigator.clipboard.writeText("");
      alert(
        "Screen prints or any form of screen capture violates the terms and conditions of Medrevisions.com"
      );
      window.location.reload();
    }
  });

  /** TO DISABLE PRINTS WHIT CTRL+P **/
  document.addEventListener("keydown", (e) => {
    if (e.ctrlKey && e.key == "p") {
      alert(
        "Screen prints or any form of screen capture violates the terms and conditions of Medrevisions.com"
      );
      e.cancelBubble = true;
      e.preventDefault();
      e.stopImmediatePropagation();
      window.location.reload();
    }
  });

  var examTimer = null;
  const reportModelClosed = (submitted) => {
    if (submitted) {
      setSnackBar({
        open: true,
        msg: "Feedback Submitted!",
      });
    }
    setReportmodal(false);
  };
  const suggestionModelClosed = (submitted) => {
    if (submitted) {
      setSnackBar({
        open: true,
        msg: "Suggession Submitted!",
      });
    }
    setSuggestionmodal(false);
  };

  function handleRefPanelClick(event) {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }
  const fetchQuestionList = async (se) => {
    try {
      const _title = new URLSearchParams(props.location.search).get("title");
      const _tag = new URLSearchParams(props.location.search).get("tag");

      if (match.params.subTopicID) {
        // title

        const pastQs = {};
        const allQs = {};
        const seQs = {};
        let _question_ref = firestore
          .collection("questions")
          .where("topicId", "==", match.params.topicOrExamId)
          .where("subTopicId", "==", match.params.subTopicID)
          .where("exam", "==", "PLAB");

        if (_title) {
          _question_ref = _question_ref.where("title", "==", _title);
        }

        if (_tag) {
          _question_ref = _question_ref.where("tag", "array-contains", _tag);
        }

        _question_ref.get().then(async (snaps) => {
          const questions = snaps.docs.map((d) => d.id);
          snaps.forEach((snap) => {
            const d = snap.data();
            if (d.pastPaperQuestion) {
              pastQs[snap.id] = true;
            }
            if (d.studyessentialQuestion) {
              seQs[snap.id] = true;
            }
            allQs[snap.id] = true;
          });

          if (se) {
            setQIds(Object.keys(seQs));
          } else {
            setQIds(questions);
          }
          setAllQuestions(allQs || {});
          setPastPaperQuestions(pastQs || {});
          setStudyessentialQuestion(seQs || {});
          await fetchQuestion(0, questions);
        });
      } else if (match.path.match(/demo/)) {
        firestore
          .collection("demoQuestions")
          .doc(match.params.type)
          .get()
          .then(async (snapShot) => {
            const data = snapShot.data();
            setQIds(data.questions);
            await fetchQuestion(0, data.questions);
          });
      } else if (match.path.match(/exam/)) {
        const snapShot = await firestore
          .collection("timedExams")
          .doc(match.params.topicOrExamId)
          .get();

        const data = snapShot.data();
        setQIds(data.questions);
        let allQs = {};
        data.questions.forEach((id) => {
          allQs[id] = true;
        });
        setAllQuestions(allQs || {});
        await fetchQuestion(0, data.questions);
      } else if (match.path.match(/revision/)) {
        firebase.auth().onAuthStateChanged((user) => {
          if (user) {
            firestore
              .collection("revision")
              .doc(user.uid)
              .get()
              .then(async (revisionSnap) => {
                const revisionData = revisionSnap.data();

                const revisionObj = getRevisionQuestions(
                  revisionData.questions
                );
                const allQuestions = [
                  ...revisionObj.one,
                  ...revisionObj.seven,
                  ...revisionObj.sixTeen,
                  ...revisionObj.thirtyFive,
                ];
                if (allQuestions.length === 0) {
                  window.location.href = "/home";
                } else {
                  setQIds(allQuestions);
                  await fetchQuestion(0, allQuestions);
                }
              });
          }
        });
      } else if (match.path.match(/tag/)) {
        const pastQs = {};
        const allQs = {};
        const seQs = {};
        firebase.auth().onAuthStateChanged((user) => {
          if (user) {
            firestore
              .collection("questions")
              .where("tags", "array-contains", _tag)
              .get()
              .then(async (tagSnap) => {
                const questions = tagSnap.docs.map((d) => d.id);
                const notes = [];
                const notetitles = [];
                tagSnap.forEach((snap) => {
                  const d = snap.data();
                  if (!notetitles.includes(d.title)) {
                    notes.push(d);
                    notetitles.push(d.title);
                  }
                  if (d.pastPaperQuestion) {
                    pastQs[snap.id] = true;
                  }
                  if (d.studyessentialQuestion) {
                    seQs[snap.id] = true;
                  }
                  allQs[snap.id] = true;
                });

                if (se) {
                  setQIds(Object.keys(seQs));
                } else {
                  setQIds(questions);
                }

                setTagNotes(notes);
                setAllQuestions(allQs || {});
                setPastPaperQuestions(pastQs || {});
                setStudyessentialQuestion(seQs || {});
                await fetchQuestion(0, questions);
              });
          }
        });
      } else if (match.path.match(/bookmarks/)) {
        firebase.auth().onAuthStateChanged((user) => {
          if (user) {
            let _bookmark_ref = firestore.collection("bookmarks");
            const _bookmark_topic = new URLSearchParams(
              props.location.search
            ).get("topic");

            _bookmark_ref
              .doc(user.uid)
              .get()
              .then(async (bookmarkSnap) => {
                const bookmarkData = bookmarkSnap.data();
                const bookmarks = Object.keys(bookmarkSnap.data() || {}).sort();
                let finalBookMarks = [];
                if (_title) {
                  bookmarks.forEach((b) => {
                    if (_bookmark_topic === "im") {
                      if (
                        bookmarkData[b]?.subTopic?.trim() === _title?.trim()
                      ) {
                        finalBookMarks.push(b);
                      }
                    } else {
                      if (bookmarkData[b]?.topic?.trim() === _title?.trim()) {
                        finalBookMarks.push(b);
                      }
                    }
                  });
                } else {
                  finalBookMarks = bookmarks;
                }
                if (finalBookMarks.length === 0) {
                  alert("No bookmark found in this topic");
                  window.location.href = "/home";
                } else {
                  setAllQuestions(finalBookMarks || {});
                  setQIds(finalBookMarks);
                  await fetchQuestion(0, finalBookMarks);
                }
              });
          }
        });
      } else if (match.path.match(/mock/)) {
        getMockPassMark();
        setMock(true);
        if (match.params.mockId == 1) {
          const subRef = firestore
            .collection("mockSubscriptions")
            .doc(firebase.auth().currentUser.uid);
          const mockSnap = await subRef.get();
          if (mockSnap.exists) {
            const sub = mockSnap.data();
            setSub(sub);
            setEndedMock(sub.exams?.["mock" + mockLevel]?.ended || false);
            if (sub.exams?.["mock" + mockLevel]?.ended != false) {
              setMockUsedSec(
                sub.exams?.["mock" + mockLevel]?.usedExamTime || 0
              );
            }
            if (sub.mockLevel >= match.params.mockId) {
              setMockLevel(sub.mockLevel);
              let mockId = "mock" + mockLevel;
              if (mockLevel == 1) {
                mockId = "MdNQ6NEm5GyAmLzLRGqf";
              }
              firestore
                .collection("exams")
                .doc(mockId)
                .get()
                .then(async (snapShot) => {
                  const data = snapShot.data();
                  setQIds(Object.keys(data.questions || {}).sort());

                  //setPastPaperQuestions(data.pastQuestions || {});
                  //setStudyessentialQuestion(data.studyessentialQuestions || {});
                  setAllQuestions(data.questions || {});
                  await fetchQuestion(
                    0,
                    Object.keys(data.questions || {}).sort()
                  );
                });
            } else {
              setMockLevel(0);
            }
          } else {
            setMockLevel(0);
          }
        } else {
          setMockLevel(match.params.mockId);
          let mockId = "mock" + match.params.mockId;
          if (match.params.mockId == 1) {
            mockId = "MdNQ6NEm5GyAmLzLRGqf";
          }
          firestore
            .collection("exams")
            .doc(mockId)
            .get()
            .then(async (snapShot) => {
              const data = snapShot.data();

              setQIds(Object.keys(data.questions || {}).sort());

              //setPastPaperQuestions(data.pastQuestions || {});
              //setStudyessentialQuestion(data.studyessentialQuestions || {});
              setAllQuestions(data.questions || {});
              await fetchQuestion(0, Object.keys(data.questions || {}).sort());
            });
        }
      } else {
        if (_title) {
          const pastQs = {};
          const allQs = {};
          const seQs = {};
          let _question_ref = firestore
            .collection("questions")
            .where("topicId", "==", match.params.topicOrExamId)
            .where("title", "==", _title)
            .where("exam", "==", "PLAB");
          _question_ref.get().then(async (snaps) => {
            const questions = snaps.docs.map((d) => d.id);
            snaps.forEach((snap) => {
              const d = snap.data();
              if (d.pastPaperQuestion) {
                pastQs[snap.id] = true;
              }
              if (d.studyessentialQuestion) {
                seQs[snap.id] = true;
              }
              allQs[snap.id] = true;
            });
            setQIds(questions);
            setAllQuestions(allQs || {});
            setPastPaperQuestions(pastQs || {});
            setStudyessentialQuestion(seQs || {});
            await fetchQuestion(0, questions);
          });
        } else {
          firestore
            .collection("topics")
            .doc(match.params.topicOrExamId)
            .get()
            .then(async (snapShot) => {
              const data = snapShot.data();

              if (se) {
                setQIds(Object.keys(data.studyessentialQuestions || {}).sort());
              } else {
                setQIds(Object.keys(data.questions || {}).sort());
              }

              setPastPaperQuestions(data.pastQuestions || {});
              setStudyessentialQuestion(data.studyessentialQuestions || {});
              setAllQuestions(data.questions || {});
              await fetchQuestion(
                0,
                Object.keys(data.questions || {}).sort(),
                _title
              );
            });
        }
      }
    } catch (error) {
      console.error("error", error);
      if (error.code === "permission-denied") {
        // Do something to show user
        window.location.href = "/auth";
      } else {
        alert("something went wrong!");
        // window.location.href = "/home";
        // Or do other things
      }
    }
  };
  const [qsType, setQsType] = React.useState("all");

  const getMockPassMark = () => {
    firestore
      .collection("admin")
      .doc("settings")
      .get()
      .then((data) => {
        setMockPass(data.data().mockPassMark);
      });
  };

  const togglePastpaperQs = async (checked) => {
    setPastPaperQsOnly(checked);
    setLoading(true);
    if (!checked) {
      setQIds(Object.keys(allQuestions || {}));
      await fetchQuestion(0, Object.keys(allQuestions || {}));
    } else {
      setQIds(Object.keys(pastPaperQuestions || {}));
      await fetchQuestion(0, Object.keys(pastPaperQuestions || {}));
    }
    setLoading(false);
  };
  const togglStudyEssentialQs = async (checked) => {
    setStudyessentialQsOnly(checked);
    setLoading(true);
    if (!checked) {
      setQIds(Object.keys(allQuestions || {}));
      await fetchQuestion(0, Object.keys(allQuestions || {}));
    } else {
      setQIds(Object.keys(studyessentialQuestion || {}));
      await fetchQuestion(0, Object.keys(studyessentialQuestion || {}));
    }
    setLoading(false);
  };

  const [isNotAnswered, setAnswerd] = useState(false);
  const [isNotUnAnswered, setUnAnswerd] = useState(false);
  const [isNotPastQs, setPastQs] = useState(false);
  const [isNotSeQs, setSeQs] = useState(false);

  const toggleBookMarkAnswerdUnAnswerdQs = async (
    type,
    isPastQs = false,
    isSeQs = false
  ) => {
    setLoading(true);
    const allQs = Object.values(allQuestions || {});
    const submitedQs = Object.keys(submissions || {}).filter(
      (s) => s !== "lastAttempted"
    );

    setAnswerd(false);
    setUnAnswerd(false);

    if (type === "all") {
      setQIds(allQs);
      await fetchQuestion(0, allQs);
    } else if (type === "answerd") {
      const answerdQs = allQs.filter((q) => submitedQs.includes(q) && q);
      if (answerdQs?.length > 0) {
        setQIds(answerdQs);
        await fetchQuestion(0, answerdQs);
      } else {
        setAnswerd(true);
      }
    } else if (type === "un-answerd") {
      const unAnswerdQs = allQs.filter((q) => !submitedQs.includes(q) && q);
      if (unAnswerdQs?.length > 0) {
        setQIds(unAnswerdQs);
        await fetchQuestion(0, unAnswerdQs);
      } else {
        setUnAnswerd(true);
      }
    }
    setLoading(false);
  };

  const toggleStudyEssentialQs = async (checked) => {
    await firestore.collection("users").doc(authUser.id).set(
      {
        studyessentialQsOnly: checked,
      },
      { merge: true }
    );
  };

  const toggleAnswerdUnAnswerdQs = async (
    type,
    isPastQs = false,
    isSeQs = false
  ) => {
    setLoading(true);
    setPastPaperQsOnly(isPastQs);
    setStudyessentialQsOnly(isSeQs);
    setQsType(type);
    setCurrentQ(null);
    setCurrentPage(0);
    setAnswerd(false);
    setUnAnswerd(false);
    setPastQs(false);
    setSeQs(false);
    toggleStudyEssentialQs(false);
    const submitedQs = Object.keys(submissions || {}).filter(
      (s) => s !== "lastAttempted"
    );

    const allQs = Object.keys(allQuestions || {});
    const pastQs = Object.keys(pastPaperQuestions || {});
    const seQs = Object.keys(studyessentialQuestion || {});
    const spQs = pastQs.concat(seQs);

    if (type === "all") {
      if (isPastQs && isSeQs) {
        setQIds(spQs);
        await toggleStudyEssentialQs(true);
        await fetchQuestion(0, spQs);
      } else if (isPastQs) {
        if (pastQs?.length > 0) {
          setQIds(pastQs);
          await fetchQuestion(0, pastQs);
        } else {
          setPastQs(true);
        }
      } else if (isSeQs) {
        if (seQs?.length > 0) {
          setQIds(seQs);
          await toggleStudyEssentialQs(true);
          await fetchQuestion(0, seQs);
        } else {
          setSeQs(true);
        }
      } else {
        setQIds(allQs);
        await fetchQuestion(0, allQs);
      }
    } else if (type === "answerd") {
      if (isPastQs && isSeQs) {
        const answerdSpQs = spQs.filter((q) => submitedQs.includes(q) && q);
        if (answerdSpQs?.length > 0) {
          setQIds(answerdSpQs);
          await toggleStudyEssentialQs(true);
          await fetchQuestion(0, answerdSpQs);
        } else {
          setAnswerd(true);
        }
      } else if (isPastQs) {
        const answerdPastQs = pastQs.filter((q) => submitedQs.includes(q) && q);
        if (answerdPastQs?.length > 0) {
          setQIds(answerdPastQs);
          await fetchQuestion(0, answerdPastQs);
        } else {
          setAnswerd(true);
        }
      } else if (isSeQs) {
        const answerdSeQs = seQs.filter((q) => submitedQs.includes(q) && q);
        if (answerdSeQs?.length > 0) {
          setQIds(answerdSeQs);
          await toggleStudyEssentialQs(true);
          await fetchQuestion(0, answerdSeQs);
        } else {
          setAnswerd(true);
        }
      } else {
        const answerdQs = allQs.filter((q) => submitedQs.includes(q) && q);
        if (answerdQs?.length > 0) {
          setQIds(answerdQs);
          await fetchQuestion(0, answerdQs);
        } else {
          setAnswerd(true);
        }
      }
    } else if (type === "un-answerd") {
      if (isPastQs && isSeQs) {
        const unAnswerdSpQs = spQs.filter((q) => !submitedQs.includes(q) && q);
        if (unAnswerdSpQs?.length > 0) {
          setQIds(unAnswerdSpQs);
          await toggleStudyEssentialQs(true);
          await fetchQuestion(0, unAnswerdSpQs);
        } else {
          setUnAnswerd(true);
        }
      } else if (isPastQs) {
        const unAnswerdPastQs = pastQs.filter(
          (q) => !submitedQs.includes(q) && q
        );
        if (unAnswerdPastQs?.length > 0) {
          setQIds(unAnswerdPastQs);
          await fetchQuestion(0, unAnswerdPastQs);
        } else {
          setUnAnswerd(true);
        }
      } else if (isSeQs) {
        const unAnswerdSeQs = seQs.filter((q) => !submitedQs.includes(q) && q);
        if (unAnswerdSeQs?.length > 0) {
          setQIds(unAnswerdSeQs);
          await toggleStudyEssentialQs(true);
          await fetchQuestion(0, unAnswerdSeQs);
        } else {
          setUnAnswerd(true);
        }
      } else {
        const unAnswerdQs = allQs.filter((q) => !submitedQs.includes(q) && q);
        if (unAnswerdQs?.length > 0) {
          setQIds(unAnswerdQs);
          await fetchQuestion(0, unAnswerdQs);
        } else {
          setUnAnswerd(true);
        }
      }
    }

    /* if (type === "all" && isPastQs) {
      if (pastQs?.length > 0) {
        setQIds(pastQs);
        await fetchQuestion(0, pastQs);
      } else {
        setPastQs(true);
      }
    } else if (type === "all" && !isPastQs) {
      setQIds(allQs);
      await fetchQuestion(0, allQs);
    }
    else if (type === "answerd" && isPastQs) {
      const answerdPastQs = pastQs.filter((q) => submitedQs.includes(q) && q);
      if (answerdPastQs?.length > 0) {
        setQIds(answerdPastQs);
        await fetchQuestion(0, answerdPastQs);
      } else {
        setAnswerd(true);
      }
    } else if (type === "answerd" && !isPastQs) {
      const answerdQs = allQs.filter((q) => submitedQs.includes(q) && q);
      if (answerdQs?.length > 0) {
        setQIds(answerdQs);
        await fetchQuestion(0, answerdQs);
      } else {
        setAnswerd(true);
      }
    } else if (type === "un-answerd" && isPastQs) {
      const unAnswerdPastQs = pastQs.filter(
        (q) => !submitedQs.includes(q) && q
      );
      if (unAnswerdPastQs?.length > 0) {
        setQIds(unAnswerdPastQs);
        await fetchQuestion(0, unAnswerdPastQs);
      } else {
        setUnAnswerd(true);
      }
    } else if (type === "un-answerd" && !isPastQs) {
      const unAnswerdQs = allQs.filter((q) => !submitedQs.includes(q) && q);
      if (unAnswerdQs?.length > 0) {
        setQIds(unAnswerdQs);
        await fetchQuestion(0, unAnswerdQs);
      } else {
        setUnAnswerd(true);
      }
    }*/

    setLoading(false);
  };
  const fetchSubmissions = async (user) => {
    const submissionSnap = await firestore
      .collection("submissions")
      .doc(user.uid)
      .get();
    const data = submissionSnap.data();
    if (data) {
      setSubmissions(data[match.params.topicOrExamId] || {});
      if (match.url.includes("mock")) {
        setSubmissions(data["mock" + match.params.mockId]);
        var correct = 0;
        var submitted = 0;
        Object.values(data["mock" + match.params.mockId]).map((e, i) => {
          if (e.correct == true) {
            correct++;
          }
          submitted++;
        });
        setMockCorrect(correct);
        setmockSubmitted(submitted);
      }
    } else {
      setSubmissions({});
    }
  };

  const fetchQuestion = (index, qIds) => {
    try {
      return firestore
        .collection("questions")
        .doc(qIds[index])
        .get()
        .then((snapShot) => {
          if (snapShot.exists) {
            const data = snapShot.data();
            data.id = snapShot.id;
            data.question = draftToHtml(data.question);
            data.description = draftToHtml(data.description).replace(
              /<br>/g,
              ""
            );

            const ql = { ...fullQuestions, [index]: data };
            setFullQuestions(ql);

            setCurrentQ(index);
            setLoading(false);
          } else {
            setLoading(false);
          }
        });
    } catch (error) {
      // window.location.href = "/auth";
      console.error("🚀 ~ n ~ error", error.code);
      if (error.code === "permission-denied") {
        // Do something to show user
        // window.location.href = "/auth";
      } else {
        alert("something went wrong!");
        // window.location.href = "/home";
        // Or do other things
      }
    }
  };

  const bookmarkQuestion = () => {
    firebase.auth().onAuthStateChanged((user) => {
      setBookmarking(true);
      if (user) {
        const q = fullQuestions[currentQ];

        const data = {
          title: q.title,
          topic: q.topic,
          subTopic: q.subTopic,
          // timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        };

        // disabled={bookmarking || Boolean((bookmarks || {})[id])}
        if (Boolean((bookmarks || {})[q.id])) {
          const cloneBookmark = Object.assign(bookmarks || {});
          delete cloneBookmark[q.id];
          firestore
            .collection("bookmarks")
            .doc(user.uid)
            .set(cloneBookmark)
            .then((submissionSnap) => {
              setBookmarking(false);
              const b = { ...cloneBookmark, [q.id]: false };
              setBookmarks({ ...cloneBookmark, [q.id]: false });
              setSnackBar({
                open: true,
                type: "success",
                msg: "Sucessfully Remove Bookmark",
              });
            });
        } else {
          firestore
            .collection("bookmarks")
            .doc(user.uid)
            .set({ [q.id]: data }, { merge: true })
            .then((submissionSnap) => {
              setBookmarking(false);
              setBookmarks({ ...bookmarks, [q.id]: true });
              setSnackBar({
                open: true,
                type: "success",
                msg: "Sucessfully Bookmarked",
              });
            });
        }
      }
    });
  };

  const getBookmarks = () => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        firestore
          .collection("bookmarks")
          .doc(user.uid)
          .get()
          .then((bookmarksSnap) => {
            setBookmarks(bookmarksSnap.data());
          });
      }
    });
  };

  const getSmartRevisionSet = () => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        firestore
          .collection("revision")
          .doc(user.uid)
          .get()
          .then((revisionSnap) => {
            setSmartRevisionSet((revisionSnap.data() || {}).questions);
          });
      }
    });
  };

  const [isExamTimeOut, setExamTimeOut] = useState(false);
  const postSubmission = () => {
    const cQuestion = fullQuestions[currentQ];
    const isCorrect = cQuestion.correctAnswer === selectedAnswer;
    setAnswerPercent(cQuestion);

    const s = { ...submissions };
    s[cQuestion.id] = { correct: isCorrect, selectedAnswer };
    setSubmissions(s);

    if (match.path.match(/exam/) && currentQ === qIds.length - 1) {
      endExam();
    }

    //end mock exam
    if (match.path.match(/mock/) && currentQ === qIds.length - 1) {
      endMockExam();
    }

    if (match.path.match(/exam/) || match.path.match(/mock/)) {
      setSubmittingAnswer(false);
      return nextQuestion();
    }
    setSubmittingAnswer(false);
    setAnswerSubmitted(true);
  };

  const saveToSmartRevision = () => {
    firebase.auth().onAuthStateChanged(function (user) {
      const cQuestion = fullQuestions[currentQ];
      const revisionRef = firestore.collection("revision").doc(user.uid);
      revisionRef
        .set(
          {
            questions: {
              [cQuestion.id]: {
                updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
                count: {},
              },
            },
          },
          { merge: true }
        )
        .then(() => {
          setSmartRevisionSet({ ...smartRevisionSet, [cQuestion.id]: true });
          setSnackBar({
            open: true,
            type: "success",
            msg: "Sucessfully added question to Smart Revision",
          });
        });
    });
  };

  const saveSubmission = () => {
    setSubmittingAnswer(true);
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        const batch = firestore.batch();
        const cQuestion = fullQuestions[currentQ];
        const isCorrect = cQuestion.correctAnswer === selectedAnswer;
        cQuestion.submissions = cQuestion.submissions || {};
        const revisionRef = firestore.collection("revision").doc(user.uid);
        if (match.path.match(/demo/)) {
          postSubmission();
          return;
        } else if (!match.path.match(/revision/)) {
          let col = match.params.topicOrExamId || cQuestion.topicId;
          if (match.path.match(/mock/)) {
            col = "mock" + match.params.mockId;
          }

          const submissionQuestionRef = firestore
            .collection("submissions")
            .doc(user.uid)
            .collection(col)
            .doc(cQuestion.id);

          let subTopicID = match?.params?.subTopicID || "";
          if (match.path.match(/mock/)) {
          }
          const submissionTopicRef = firestore
            .collection("submissions")
            .doc(user.uid);
          batch.set(
            submissionQuestionRef,
            {
              correct: isCorrect,
              selectedAnswer,
              subTopicID: subTopicID,
            },
            { merge: true }
          );
          if (!isCorrect && !match.path.match(/mock/)) {
            batch.set(
              revisionRef,
              {
                questions: {
                  [cQuestion.id]: {
                    updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
                    count: {},
                  },
                },
              },
              { merge: true }
            );
          }
          if (!match.path.match(/exam/)) {
            batch.set(
              submissionTopicRef,
              {
                [cQuestion.topicId]: {
                  lastAttempted:
                    firebase.firestore.FieldValue.serverTimestamp(),
                },
              },
              { merge: true }
            );
          }
        } else {
          // Its in revision mode
          batch.set(
            revisionRef,
            {
              questions: {
                [cQuestion.id]: {
                  updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
                  count: { [Date.now()]: true },
                },
              },
            },
            { merge: true }
          );
        }

        batch.commit().then(() => {
          postSubmission();
        });
      } else {
        if (match.path.match(/demo/)) {
          postSubmission();
        } else {
          window.location.href = "/";
        }
      }
    });
  };

  const setMockAnswers = () => {
    const question = fullQuestions[currentQ];
    setSelectedAnswer(
      submissions["mock" + match.params.mockId][question.id].selectedAnswer
    );
    setSubmittedAnswer(
      submissions["mock" + match.params.mockId][question.id].selectedAnswer
    );
    setAnswerSubmitted(true);
    setAnswerPercent(question);
  };

  const checkAndSetAnswered = () => {
    const question = fullQuestions[currentQ];
    if (question && submissions[question.id] && !match.path.match(/mock/)) {
      setSelectedAnswer(submissions[question.id].selectedAnswer);
      setSubmittedAnswer(submissions[question.id].selectedAnswer);
      setAnswerSubmitted(true);
      setAnswerPercent(question);
    } else if (question && match.path.match(/exam/) && expiredExam) {
      setSelectedAnswer(question.correctAnswer);
      setSubmittedAnswer(null);
      setAnswerSubmitted(true);
      setAnswerPercent(question);
    } else if (question && match.path.match(/mock/)) {
      setSelectedAnswer(submissions[question.id]?.selectedAnswer);
      setSubmittedAnswer(submissions[question.id]?.selectedAnswer);
    } else if (question && match.path.match(/mock/) && endedMock) {
      setSelectedAnswer(submissions[question.id]?.selectedAnswer);
      setSubmittedAnswer(submissions[question.id]?.selectedAnswer);
      setAnswerSubmitted(true);
      setAnswerPercent(question);
    } else {
      setSelectedAnswer(null);
      setSubmittedAnswer(null);
      setAnswerSubmitted(false);
    }
  };

  const setAnswerPercent = (question) => {
    const percents = {};
    const totoalSubmissions = Object.values(question.submissions || {}).reduce(
      (p, c) => p + c,
      0
    );
    if (totoalSubmissions === 0) {
      setShowPercents(false);
    } else {
      Object.keys(question.answers || {}).forEach((aId) => {
        percents[aId] = Math.floor(
          ((question.submissions[aId] || 0) / totoalSubmissions) * 100
        );
      });
      setSubmissionPercent(percents);
    }
  };

  const preQuestion = () => {
    const q = currentQ === 0 ? qIds.length - 1 : currentQ - 1;
    setCurrentQuestion(q);
    setRenderAll(true);
    setShowDescription(true);
  };

  const nextQuestion = () => {
    const q = currentQ === qIds.length - 1 ? 0 : currentQ + 1;
    setCurrentQuestion(q);
    setRenderAll(true);
    setShowDescription(true);
  };

  const preNote = () => {
    const q = tagNotecurrentQ === 0 ? tagNotes.length - 1 : tagNotecurrentQ - 1;
    setTagNotecurrentQ(q);
  };

  const nextNote = () => {
    const q = tagNotecurrentQ === tagNotes.length - 1 ? 0 : tagNotecurrentQ + 1;
    setTagNotecurrentQ(q);
  };

  const setCurrentQuestion = async (q) => {
    if (currentQ !== q) {
      if (!fullQuestions[q]) {
        await fetchQuestion(q, qIds);
      }
      setCurrentQ(q);
    }
    window.scrollTo(0, 0);
  };

  const [showDescription, setShowDescription] = useState(false);

  const submitAnswer = () => {
    if (selectedAnswer) {
      saveSubmission();
      setRenderAll(true);
      setShowDescription(true);
    } else {
      alert("Please select an answer");
    }
  };

  const [secRemain, setUsedSec] = useState(0);

  const startMockExam = () => {
    const mockTime = Object.keys(allQuestions).length;
    let timeElapsed = 0;
    let _used_sec = 0;
    if (endedMock) {
      mockExamTimer = null;
      setMockModal(false);
      setMockAnswers();
    } else {
      window.mockExamTimer = setInterval(() => {
        timeElapsed++;
        let secondsRemaining = mockTime * 60 - timeElapsed;
        console.log(secondsRemaining);
        const c = mockUsedSec;
        //const c = 0;
        secondsRemaining = secondsRemaining - c;
        const min = Math.floor(secondsRemaining / 60);
        const sec = secondsRemaining - min * 60;
        _used_sec++;
        setMockUsedSec(c + _used_sec);
        if (secondsRemaining < 0) {
          clearInterval(window.mockExamTimer);
          setEndedMock(true);
          setMockAnswers();
        }
        setMockExamSeconds(Math.floor(sec));
        setMockExamMin(min);
      }, 1000);
      setMockModal(false);
    }
  };

  const endMockExam = () => {
    clearInterval(window.mockExamTimer);
    setEndedMock(true);
    firestore
      .collection("mockSubscriptions")
      .doc(authUser.id)
      .set(
        { exams: { ["mock" + mockLevel]: { ended: true } } },
        { merge: true }
      )
      .then(() => {
        window.location.reload();
      });
  };

  const pauseMockExam = () => {
    clearInterval(window.mockExamTimer);
    firestore
      .collection("mockSubscriptions")
      .doc(authUser.id)
      .set(
        {
          exams: {
            ["mock" + mockLevel]: { pause: true, usedExamTime: mockUsedSec },
          },
        },
        { merge: true }
      )
      .then(() => {
        setMockModal(true);
      });
  };

  const updateExamTimes = (exam) => {
    // examPause = exam?.pause;
    let timeElapsed = 0;
    let _used_sec = 0;
    if (exam.ended) {
      setExpiredExam(true);
    } else {
      if (exam?.pause) {
        clearInterval(examTimer);
        examTimer = null;
        // timeElapsed = Date.now() / 1000 - exam.pauseAt.seconds;
        // const secondsRemaining = exam.time * 60 - timeElapsed;
        // const min = Math.floor(secondsRemaining / 60);
        // const sec = secondsRemaining - min * 60;
        // setExpiredExam(secondsRemaining < 0);
        // setExamSeconds(Math.floor(sec));
        // setExamMin(min);
      } else if (exam.view && exam.resumeAt) {
        if (!examTimer) {
          examTimer = setInterval(() => {
            timeElapsed = Date.now() / 1000 - exam.resumeAt.seconds;
            let secondsRemaining = exam.time * 60 - timeElapsed;
            const c = exam.usedExamTime ? exam.usedExamTime : 0;
            secondsRemaining = secondsRemaining - c;
            const min = Math.floor(secondsRemaining / 60);
            const sec = secondsRemaining - min * 60;
            _used_sec++;
            setUsedSec(c + _used_sec);
            if (secondsRemaining < 0) {
              setExamTimeOut(true);
              setExpiredExam(true);
              // saveSubmission();
              endExam();
            }

            setExamSeconds(Math.floor(sec));
            setExamMin(min);
          }, 1000);

          if (timeElapsed < 0) clearInterval(examTimer);
        }
      } else if (!exam.view && exam.startedAt) {
        examTimer = setInterval(() => {
          timeElapsed = Date.now() / 1000 - exam.startedAt.seconds;
          const secondsRemaining = exam.time * 60 - timeElapsed;
          const min = Math.floor(secondsRemaining / 60);
          const sec = secondsRemaining - min * 60;
          if (secondsRemaining < 0) {
            setExamTimeOut(true);
            setExpiredExam(true);
            // saveSubmission();
            endExam();
            // saveSubmission();
            // setExpiredExam(true);
          }
          setExamSeconds(Math.floor(sec));
          setExamMin(min);
          _used_sec++;
          setUsedSec(_used_sec);
        }, 1000);
        if (timeElapsed < 0) clearInterval(examTimer);
      }
    }
  };

  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackBar({ open: false });
  };

  const endExam = () => {
    // console.log("b4 submissions", submissions);
    // const score = Object.values(submissions || {}).filter(
    //   (s) => s.correct
    // ).length;
    // console.log("endExam", score);
    // console.log({ submissions });
    firestore
      .collection("timedExams")
      .doc(match.params.topicOrExamId)
      .set({ ended: true }, { merge: true })
      .then(() => {
        window.location.reload();
      });
  };
  const [open, setOpen] = useState(false);
  const pauseExam = () => {
    clearInterval(examTimer);

    firestore
      .collection("timedExams")
      .doc(match.params.topicOrExamId)
      .set(
        {
          view: true,
          pause: true,
          pauseAt: firebase.firestore.FieldValue.serverTimestamp(),
          usedExamTime: secRemain,
        },
        { merge: true }
      )
      .then(() => {
        setOpen(true);
        // alert("exm pause");
        // window.location.reload();
      });
  };
  const [authUser, setAuthUser] = useState({});

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        firestore
          .collection("users")
          .doc(user.uid)
          .get()
          .then((doc) => {
            if (doc.exists) {
              var data = doc.data();
              setAuthUser(data);
              setIsAdmin(data.admin);
              setStudyessentialQsOnly(data.studyessentialQsOnly || false);
            }
            fetchQuestionList(data.studyessentialQsOnly);
          })
          .catch((error) => {
            console.error("[signInSuccessCallBack]", error);
          });

        fetchSubmissions(user);
        getBookmarks();
        getSmartRevisionSet();
        setRenderAll(true);
        setShowDescription(true);
        if (match.path.match(/exam/)) {
          firestore
            .collection("timedExams")
            .doc(match.params.topicOrExamId)
            .onSnapshot((examSnap) => {
              const exam = examSnap.data();
              setExam(exam);
              updateExamTimes(exam);
            });
        } else {
          setTimeout(() => {
            // For some reson the user object was not initialized
            // So we redirect the user to login
            const user = firebase.auth().currentUser;
            if (!user) {
              alert(
                "We could not authenticate your account, please refresh this page to try again."
              );
            }
          }, 5000);
        }
      } else {
        if (match.path.match(/demo/)) {
          fetchQuestionList(false);
        }
      }
    });
  }, []);

  const [notesTabKey, setTabKey] = React.useState("rn");
  useEffect(() => {
    checkAndSetAnswered();
    setTabKey("rn");
    setRenderAll(true);
    setShowDescription(true);
  }, [currentQ]);

  const {
    id,
    topic,
    answers,

    question,
    answerDescriptions,
    correctAnswer,
    description,
    _description,
    revisionNotes,
    explanationNotes,
    helpfulLinks,
    createdAt,
  } = fullQuestions[currentQ] || {};
  const hideDesriptions = match.path.match(/exam/) && !expiredExam;

  const openRefPanel = Boolean(anchorEl);
  const refId = openRefPanel ? "simple-popper" : undefined;

  const [toggle, setToggle] = useState("");
  const onClick = (name) => setToggle(toggle === name ? "" : name);

  const [selectedAnswerIndex, setIndex] = useState(-1);
  const [renderAll, setRenderAll] = useState(true);
  const toggleAnswerDescription = (i) => {
    setShowDescription(!showDescription);
    setIndex(i);
    setRenderAll(false);
  };
  const nextIcon = React.createElement("i", {
    className: "mdi mdi-chevron-double-right text-info",
  });
  const prevIcon = React.createElement("i", {
    className: "mdi mdi-chevron-double-left text-info",
  });

  const [isFormIncomplete, setIsFormIncomplete] = useState(true);
  // const _title = new URLSearchParams(props.location.search).get("title");
  const history = useHistory();

  const offset = currentPage * PER_PAGE;
  const isShowForTimedExm = match.path.match(/exam/) && expiredExam;
  const isShowForMockExm = match.path.match(/mock/) && endedMock;
  const currentPageData = qIds
    ?.slice(offset, offset + PER_PAGE)
    .map((id, i) => (
      <div key={i} class="col-auto m-2 ">
        <div class="mx-auto ">
          <div
            // className=""
            className={`question-ids ${
              currentQ === i && "question-ids-selected"
            }`}
            style={{
              ...(submissions[id] &&
                submissions[id]?.correct &&
                !match.path.match(/exam/) &&
                !match.path.match(/mock/) && {
                  background: "#d8f3d9e0",
                }),
              ...(submissions[id] &&
                submissions[id]?.correct &&
                isShowForTimedExm && {
                  background: "#d8f3d9e0",
                }),
              ...(submissions[id] &&
                submissions[id]?.correct &&
                isShowForMockExm && {
                  background: "#d8f3d9e0",
                }),
              ...(submissions[id] &&
                submissions[id]?.correct === false &&
                !match.path.match(/exam/) &&
                !match.path.match(/mock/) && {
                  background: "#ffcdd2",
                }),
              ...(submissions[id] &&
                submissions[id]?.correct === false &&
                isShowForTimedExm && {
                  background: "#ffcdd2",
                }),
              ...(submissions[id] &&
                submissions[id]?.correct === false &&
                isShowForMockExm && {
                  background: "#ffcdd2",
                }),
            }}
            onClick={() => {
              setCurrentQuestion(i + offset);
            }}
          >
            {/* {i === 1 && <div class="ribbon">New! </div>} */}
            {!match.path.match(/exam/) && pastPaperQuestions[id] ? (
              <Tooltip
                title="This is a high yield or a recall question."
                style={{
                  position: "absolute",
                  top: "-10px",
                }}
              >
                {/* <StarsIcon  fontSize="small" /> */}
                <span>
                  <i class="ti-star" style={{ color: "goldenrod" }}></i>
                </span>
              </Tooltip>
            ) : null}
            <div className="d-flex flex-column align-items-center justify-content-center ">
              {match.path.match(/exam/) && !expiredExam && submissions[id] && (
                <span
                  style={{
                    position: "absolute",
                  }}
                >
                  <i
                    style={{ color: "#a4a5a9a1" }}
                    class="las la-slash la-2x"
                  ></i>
                  {/* {fullQuestions[i]?.createdAt?.seconds} */}
                </span>
              )}
              <span>{i + offset + 1}</span>
            </div>
          </div>
        </div>
      </div>
    ));

  const pageCount = Math.ceil(qIds?.length / PER_PAGE);
  const isBookMarked = Boolean((bookmarks || {})[id]);
  return (
    <>
      {/* <Prompt when={true} message="Are you sure you want to leave?" /> */}

      <Fragment>
        <ChatBox onClick={() => onClick("chatbox")} toggle={toggle} />
        {/* <PageTitle
          style={{ marginBottom: 0 }}
          activeMenu="List"
          motherMenu="Questions"
        /> */}
        {/* <div className="page-titles d-flex align-items-center justify-content-between flex-wrap"> */}
        <Row className=" bg-light p-2   align-items-center ">
          <Col md={4} lg={4} sm={12}>
            <div className=" d-flex align-items-center ">
              <i
                onClick={() => {
                  history.goBack();
                }}
                class="las la-arrow-circle-left la-2x c-pointer mr-2"
              ></i>
              <h5 className="mb-0 text-capitalize">
                {match.path.match(/exam/)
                  ? ""
                  : match?.params?.topicOrExamId
                  ? match?.params?.topicOrExamId === "gmHkkIMWSnVnOtMnOWhV"
                    ? "Emergency Medicine"
                    : match?.params?.topicOrExamId
                  : null}

                {/*  */}
              </h5>
            </div>
          </Col>
          <Col md={8} lg={8} sm={12}>
            {myParam !== "notes" && (
              <>
                <div className=" d-flex  align-items-center justify-content-center flex-wrap ">
                  <span></span>
                  <Button
                    variant="info  btn-xxs mx-1 d-flex align-items-center justify-content-center btn-rounded"
                    onClick={() => {
                      setReportmodal(true);
                    }}
                  >
                    <i class="las la-comment-dots la-2x mr-1"></i>
                    Feedback
                  </Button>

                  <Button
                    onClick={saveToSmartRevision}
                    disabled={
                      match.path.match(/mock/)
                        ? true
                        : Boolean((smartRevisionSet || {})[id])
                    }
                    variant="info  btn-xxs mx-1 d-flex align-items-center justify-content-center btn-rounded"
                  >
                    <i class="las la-folder-plus la-2x mr-1 "></i>
                    Smart Revision
                  </Button>
                  {!match.path.match(/bookmarks/) && (
                    <Button
                      onClick={bookmarkQuestion}
                      // disabled={bookmarking || Boolean((bookmarks || {})[id])}
                      disabled={match.path.match(/mock/) ? true : bookmarking}
                      variant={` ${
                        !isBookMarked ? "btn-outline-info" : "btn-info"
                      } btn-xxs mx-1 d-flex align-items-center justify-content-center btn-rounded`}
                    >
                      <i class="las la-bookmark la-2x mr-1"></i>
                      {isBookMarked ? "Bookmarked" : "Bookmark"}
                      {/* <Hidden smDown>  </Hidden> */}
                    </Button>
                  )}
                  {/* <Button
                    variant="info tp-btn btn-xxs mx-1 d-flex align-items-center justify-content-center btn-rounded"
                    onClick={() => onClick("chatbox")}
                  >
                    <i class="las la-exclamation la-2x mr-1"></i>
                    References
                  </Button> */}
                  {match.path.match(/demo/) && (
                    <Button
                      onClick={() => {
                        window.location = "/auth";
                      }}
                      variant="warning  btn-xxs mx-1 d-flex align-items-center justify-content-center btn-rounded"
                    >
                      {" "}
                      <i class="las la-user-plus la-2x mr-1 "></i>
                      Sign up
                    </Button>
                  )}
                </div>
              </>
            )}
          </Col>
        </Row>
        {exam && !expiredExam && (
          <>
            <Row className="mt-2">
              <Col md={6} lg={4} sm={12}>
                <div
                  className="timer px-2 py-1"
                  style={{
                    borderRadius: "3px",
                    border: "1px dotted #181f39",
                  }}
                >
                  <p className="mb-1">Time remaining (MM:SS)</p>
                  <h3 className="mb-0">{`${examMin}:${examSeconds}`}</h3>
                </div>
              </Col>
              <Col
                md={6}
                lg={8}
                sm={12}
                className=" align-items-center flex-row  d-flex p-2 justify-content-lg-end justify-content-md-end justify-content-sm-center"
              >
                {!expiredExam && (
                  <>
                    <button
                      disabled={expiredExam}
                      onClick={endExam}
                      type="button"
                      class="btn-xxs mr-2 btn btn-outline-warning btn-rounded d-flex align-items-center justify-content-center"
                    >
                      <i class="las la-stop-circle mr-1 la-2x"></i>
                      End Exam
                    </button>

                    <button
                      disabled={expiredExam}
                      onClick={pauseExam}
                      class="btn-xxs mr-2 btn btn-outline-info btn-rounded d-flex align-items-center justify-content-center"
                    >
                      <i class="las la-pause-circle mr-1 la-2x"></i>
                      Pause Exam
                    </button>
                  </>
                )}
              </Col>
            </Row>
          </>
        )}
        {mock && !endedMock && (
          <>
            <Row className="mt-2">
              <Col md={6} lg={4} sm={12}>
                <div
                  className="timer px-2 py-1"
                  style={{
                    borderRadius: "3px",
                    border: "1px dotted #181f39",
                  }}
                >
                  <p className="mb-1">Time remaining (MM:SS)</p>
                  <h3 className="mb-0">{`${mockExamMin}:${mockExamSeconds}`}</h3>
                </div>
              </Col>
              <Col
                md={6}
                lg={8}
                sm={12}
                className=" align-items-center flex-row  d-flex p-2 justify-content-lg-end justify-content-md-end justify-content-sm-center"
              >
                {mock && !endedMock && !mockModal && (
                  <>
                    <button
                      disabled={endedMock}
                      onClick={endMockExam}
                      type="button"
                      class="btn-xxs mr-2 btn btn-outline-warning btn-rounded d-flex align-items-center justify-content-center"
                    >
                      <i class="las la-stop-circle mr-1 la-2x"></i>
                      End Exam
                    </button>

                    <button
                      disabled={endedMock}
                      onClick={pauseMockExam}
                      class="btn-xxs mr-2 btn btn-outline-info btn-rounded d-flex align-items-center justify-content-center"
                    >
                      <i class="las la-pause-circle mr-1 la-2x"></i>
                      Pause Exam
                    </button>
                  </>
                )}
              </Col>
            </Row>
          </>
        )}
        {/* </div> */}

        {match.path.match(/tag/) ||
        match.path.match(/bookmarks/) ||
        match.path.match(/revision/) ? (
          myParam === "questions" ? (
            <Container>
              <RenderQuestions
                authUser={authUser}
                isNotAnswered={isNotAnswered}
                isNotUnAnswered={isNotUnAnswered}
                isNotPastQs={isNotPastQs}
                isNotSeQs={isNotSeQs}
                allQuestions={allQuestions}
                qsType={qsType}
                setQsType={setQsType}
                pastPaperQsOnly={pastPaperQsOnly}
                togglePastpaperQs={togglePastpaperQs}
                studyessentialQsOnly={studyessentialQsOnly}
                togglStudyEssentialQs={togglStudyEssentialQs}
                studyessentialQuestions={studyessentialQuestion}
                toggleAnswerdUnAnswerdQs={
                  match.path.match(/bookmarks/)
                    ? toggleBookMarkAnswerdUnAnswerdQs
                    : toggleAnswerdUnAnswerdQs
                }
                pauseExam={pauseExam}
                exam={exam}
                endExam={endExam}
                examMin={examMin}
                examSeconds={examSeconds}
                pastPaperQuestions={pastPaperQuestions}
                question={question}
                answers={answers}
                expiredExam={expiredExam}
                answerSubmitted={answerSubmitted}
                match={match}
                toggleAnswerDescription={toggleAnswerDescription}
                setSelectedAnswer={setSelectedAnswer}
                selectedAnswer={selectedAnswer}
                submittedAnswer={submittedAnswer}
                hideDesriptions={hideDesriptions}
                correctAnswer={correctAnswer}
                showPercents={showPercents}
                submissionPercent={submissionPercent}
                showDescription={showDescription}
                selectedAnswerIndex={selectedAnswerIndex}
                renderAll={renderAll}
                answerDescriptions={answerDescriptions}
                classes={classes}
                currentQ={currentQ}
                setReportmodal={setReportmodal}
                bookmarking={bookmarking}
                bookmarkQuestion={bookmarkQuestion}
                bookmarks={bookmarks}
                id={id}
                saveToSmartRevision={saveToSmartRevision}
                smartRevisionSet={smartRevisionSet}
                preQuestion={preQuestion}
                nextQuestion={nextQuestion}
                qIds={qIds}
                submitAnswer={submitAnswer}
                submittingAnswer={submittingAnswer}
                loading={loading}
                onClick={onClick}
                submissions={submissions}
                currentPageData={currentPageData}
                handlePageClick={handlePageClick}
                pageCount={pageCount}
                endedMock={endedMock}
              />
              {loading ? (
                <p className="text-center mt-4">
                  {/* <i
                      class="fa fa-circle-o-notch fa-spin fa-2x fa-fw "
                      style={{ color: "#28435861" }}
                    ></i>
                    <span class="sr-only">Loading...</span> */}
                </p>
              ) : (
                answerSubmitted &&
                !hideDesriptions && (
                  <RenderNotes
                    authUser={authUser}
                    setSuggestionmodal={setSuggestionmodal}
                    setTabKey={setTabKey}
                    notesTabKey={notesTabKey}
                    _description={_description}
                    revisionNotes={revisionNotes}
                    explanationNotes={explanationNotes}
                    answerSubmitted={answerSubmitted}
                  />
                )
              )}
            </Container>
          ) : null
        ) : (
          <Container>
            <RenderQuestions
              authUser={authUser}
              isNotAnswered={isNotAnswered}
              isNotUnAnswered={isNotUnAnswered}
              isNotPastQs={isNotPastQs}
              isNotSeQs={isNotSeQs}
              allQuestions={allQuestions}
              qsType={qsType}
              setQsType={setQsType}
              toggleAnswerdUnAnswerdQs={toggleAnswerdUnAnswerdQs}
              pastPaperQsOnly={pastPaperQsOnly}
              togglePastpaperQs={togglePastpaperQs}
              studyessentialQsOnly={studyessentialQsOnly}
              toggeStudyEssentialQs={togglStudyEssentialQs}
              studyessentialQuestions={studyessentialQuestion}
              exam={exam}
              endExam={endExam}
              pauseExam={pauseExam}
              examMin={examMin}
              examSeconds={examSeconds}
              pastPaperQuestions={pastPaperQuestions}
              question={question}
              answers={answers}
              expiredExam={expiredExam}
              answerSubmitted={answerSubmitted}
              match={match}
              toggleAnswerDescription={toggleAnswerDescription}
              setSelectedAnswer={setSelectedAnswer}
              selectedAnswer={selectedAnswer}
              submittedAnswer={submittedAnswer}
              hideDesriptions={hideDesriptions}
              correctAnswer={correctAnswer}
              showPercents={showPercents}
              submissionPercent={submissionPercent}
              showDescription={showDescription}
              selectedAnswerIndex={selectedAnswerIndex}
              renderAll={renderAll}
              answerDescriptions={answerDescriptions}
              classes={classes}
              currentQ={currentQ}
              setReportmodal={setReportmodal}
              bookmarking={bookmarking}
              bookmarkQuestion={bookmarkQuestion}
              bookmarks={bookmarks}
              id={id}
              saveToSmartRevision={saveToSmartRevision}
              smartRevisionSet={smartRevisionSet}
              preQuestion={preQuestion}
              nextQuestion={nextQuestion}
              qIds={qIds}
              submitAnswer={submitAnswer}
              submittingAnswer={submittingAnswer}
              loading={loading}
              onClick={onClick}
              submissions={submissions}
              currentPageData={currentPageData}
              handlePageClick={handlePageClick}
              pageCount={pageCount}
              endedMock={endedMock}
            />
          </Container>
        )}

        {match.path.match(/tag/) ||
        match.path.match(/bookmarks/) ||
        match.path.match(/revision/) ? (
          myParam === "notes" ? (
            <>
              {match.path.match(/tag/) ? (
                <>
                  <div className="question-header shadow bg-white mb-2 py-2 mt-2 px-1">
                    <button
                      type="button"
                      onClick={preNote}
                      class="btn btn-outline-dark btn-rounded btn-xxs d-flex align-items-center justify-content-center"
                    >
                      <i class="las la-angle-left la-lg"></i>
                    </button>

                    <div className="spacer" />
                    <h5 className="m-0 mx-3">
                      {tagNotecurrentQ + 1}/{tagNotes.length}
                    </h5>
                    <div className="spacer" />

                    <button
                      type="button"
                      onClick={nextNote}
                      class="btn btn-outline-dark btn-rounded btn-xxs d-flex align-items-center justify-content-center"
                    >
                      <i class="las la-angle-right la-lg"></i>
                    </button>
                  </div>

                  <RenderNotes
                    authUser={authUser}
                    setSuggestionmodal={setSuggestionmodal}
                    setTabKey={setTabKey}
                    notesTabKey={notesTabKey}
                    _description={tagNotes[tagNotecurrentQ]?._description}
                    revisionNotes={tagNotes[
                      tagNotecurrentQ
                    ]?.revisionNotes.replace(
                      /(<table\b[^>]*\bstyle=["'][^"']*)\bwidth\s*:\s*[^;]+;?/gi,
                      "$1"
                    )}
                    explanationNotes={
                      tagNotes[tagNotecurrentQ]?.explanationNotes
                    }
                    answerSubmitted={tagNotes[tagNotecurrentQ]?.answerSubmitted}
                  />
                  {/* )} */}
                  {tagNotes[tagNotecurrentQ]?.helpfulLinks &&
                    tagNotes[tagNotecurrentQ]?.helpfulLinks.length > 0 && (
                      <RenderHelpfullLinks
                        helpfulLinks={tagNotes[tagNotecurrentQ]?.helpfulLinks}
                      />
                    )}
                </>
              ) : (
                <>
                  <div className="question-header shadow bg-white mb-2 py-2 mt-2 px-1">
                    <button
                      type="button"
                      onClick={preQuestion}
                      class="btn btn-outline-dark btn-rounded btn-xxs d-flex align-items-center justify-content-center"
                    >
                      <i class="las la-angle-left la-lg"></i>
                    </button>

                    <div className="spacer" />
                    <h5 className="m-0 mx-3">
                      {currentQ + 1}/{qIds.length}
                    </h5>
                    <div className="spacer" />

                    <button
                      type="button"
                      onClick={nextQuestion}
                      class="btn btn-outline-dark btn-rounded btn-xxs d-flex align-items-center justify-content-center"
                    >
                      <i class="las la-angle-right la-lg"></i>
                    </button>
                    {/* <IconButton
                  aria-label="Previous Question"
                  onClick={preQuestion}
                >
                  <KeyboardArrowLeftIcon fontSize="small" />
                </IconButton>
                <div className="spacer" />
                <p className="m-0">
                  {currentQ + 1}/{qIds.length}
                </p>
                <div className="spacer" />

                <IconButton aria-label="Next Question" onClick={nextQuestion}>
                  <KeyboardArrowRightIcon fontSize="small" />
                </IconButton> */}
                  </div>
                  {/* {answerSubmitted && !hideDesriptions && ( */}

                  <RenderNotes
                    authUser={authUser}
                    setSuggestionmodal={setSuggestionmodal}
                    setTabKey={setTabKey}
                    notesTabKey={notesTabKey}
                    _description={_description}
                    revisionNotes={revisionNotes}
                    explanationNotes={explanationNotes}
                    answerSubmitted={answerSubmitted}
                  />
                  {/* )} */}
                  {helpfulLinks && helpfulLinks.length > 0 && (
                    <RenderHelpfullLinks helpfulLinks={helpfulLinks} />
                  )}
                </>
              )}
            </>
          ) : null
        ) : (
          <>
            {/* {answerSubmitted && !hideDesriptions && ( */}
            {loading ? (
              <p className="text-center mt-4">
                {/* <i
                  class="fa fa-circle-o-notch fa-spin fa-2x fa-fw "
                  style={{ color: "#28435861" }}
                ></i>
                <span class="sr-only">Loading...</span> */}
              </p>
            ) : (
              <>
                {answerSubmitted && !hideDesriptions && (
                  <RenderNotes
                    authUser={authUser}
                    setSuggestionmodal={setSuggestionmodal}
                    setTabKey={setTabKey}
                    notesTabKey={notesTabKey}
                    _description={_description}
                    revisionNotes={revisionNotes}
                    explanationNotes={explanationNotes}
                    answerSubmitted={answerSubmitted}
                  />
                )}
                {helpfulLinks &&
                  helpfulLinks.length > 0 &&
                  answerSubmitted &&
                  !hideDesriptions && (
                    <RenderHelpfullLinks helpfulLinks={helpfulLinks} />
                  )}
              </>
            )}
          </>
        )}
      </Fragment>

      <Container className="questions-container">
        <Row container spacing={2}>
          <Col item xs={12} sm={8} md={9}>
            {!loading ? (
              <>
                {/* <div className="question-header">
                  <IconButton
                    aria-label="Previous Question"
                    onClick={preQuestion}
                  >
                    <KeyboardArrowLeftIcon fontSize="large" />
                  </IconButton>
                  <div className="spacer" />
                  <Typography variant="h4" align="center" component="p">
                    {currentQ + 1}/{qIds.length}
                  </Typography>
                  <div className="spacer" />

                  <IconButton aria-label="Next Question" onClick={nextQuestion}>
                    <KeyboardArrowRightIcon fontSize="large" />
                  </IconButton>
                </div> */}

                {/* <Paper className="question-content">
                  <div className="question-actions">
                    <Typography variant="subtitle2">
                      Question: {currentQ + 1}
                    </Typography>
                    <div className="spacer" />
                    <Button
                      size="small"
                      onClick={() => {
                        setReportmodal(true);
                      }}
                    >
                      <ReportIcon />
                      <Hidden smDown> Feedback </Hidden>
                    </Button>
                    {!match.path.match(/bookmarks/) && (
                      <Button
                        size="small"
                        aria-label="Bookmark"
                        onClick={bookmarkQuestion}
                        disabled={bookmarking || Boolean((bookmarks || {})[id])}
                        color="primary"
                      >
                        <BookmarkIcon />
                        <Hidden smDown> Bookmark </Hidden>
                      </Button>
                    )}

                    <Button
                      size="small"
                      aria-label="Bookmark"
                      onClick={saveToSmartRevision}
                      disabled={Boolean((smartRevisionSet || {})[id])}
                      color="primary"
                    >
                      <AddIcon />

                      <Hidden smDown> Smart Revision </Hidden>
                    </Button>
                  </div>
                  {question && isAdmin && (
                    <div>
                      <Typography variant="subtitle2">
                        Question From New Editor
                      </Typography>
                      <TinyView value={question} />
                    </div>
                  )}

                  <Typography variant="body1" component="span">
                    <span
                      className="question-text"
                      dangerouslySetInnerHTML={{
                        __html: question,
                      }}
                    />
                    {!question && (
                      <Typography variant="h5" color="textSecondary">
                        This question has been removed
                      </Typography>
                    )}
                  </Typography>

                  {question && (
                    <div className="answers">
                      <FormControl component="fieldset">
                        <FormLabel component="legend">Answers</FormLabel>

                        <RadioGroup
                          aria-label="Answer"
                          name="answer"
                          value={selectedAnswer}
                          onChange={(_, v) => {
                            setSelectedAnswer(v);
                          }}
                        >
                          {Object.keys(answers || {}).map((a) => (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <FormControlLabel
                                disabled={
                                  match.path.match(/exam/)
                                    ? expiredExam
                                      ? true
                                      : false
                                    : answerSubmitted || expiredExam
                                }
                                key={a}
                                value={a}
                                control={<Radio />}
                                label={
                                  <div className="answer-label">
                                    <Typography
                                      variant="subtitle1"
                                      className="answer-text"
                                    >
                                      {answers[a]}
                                    </Typography>
                                    {answerSubmitted && !hideDesriptions && (
                                      <>
                                        {(selectedAnswer === a ||
                                          correctAnswer === a) && (
                                          <Chip
                                            size="small"
                                            avatar={
                                              <Avatar>
                                                {correctAnswer === a ? (
                                                  <CheckIcon />
                                                ) : (
                                                  <ClearIcon />
                                                )}
                                              </Avatar>
                                            }
                                            label={
                                              correctAnswer === a
                                                ? "Correct!"
                                                : "Wrong"
                                            }
                                            className={`${
                                              correctAnswer === a
                                                ? "correct"
                                                : "wrong"
                                            } chip`}
                                          />
                                        )}
                                        {showPercents && (
                                          <Chip
                                            className="chip"
                                            size="small"
                                            label={`${
                                              submissionPercent[a] || 0
                                            }%`}
                                          />
                                        )}
                                      </>
                                    )}
                                  </div>
                                }
                              />
                              {isAdmin &&
                                answerSubmitted &&
                                !hideDesriptions &&
                                answerDescriptions[a] && (
                                  <>
                                    <div
                                      className={
                                        selectedAnswer === a ||
                                        correctAnswer === a
                                          ? correctAnswer === a
                                            ? classes.correct
                                            : classes.wrong
                                          : classes.info
                                      }
                                    >
                                      {" "}
                                      <TinyView value={answerDescriptions[a]} />
                                    </div>
                                  </>
                                )}
                            </div>
                          ))}
                        </RadioGroup>
                      </FormControl>
                    </div>
                  )}

                  <CardActions>
                    <div className="spacer" />
                    {answerSubmitted ? (
                      <>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={nextQuestion}
                        >
                          Next Question
                          <NavigateNextIcon />
                        </Button>
                      </>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={submitAnswer}
                        disabled={submittingAnswer || expiredExam}
                      >
                        {submittingAnswer ? (
                          <CircularProgress size={30} />
                        ) : match.path.match(/exam/) &&
                          currentQ === qIds.length - 1 ? (
                          "Submit and end Exam"
                        ) : (
                          "Submit Answer"
                        )}
                      </Button>
                    )}
                  </CardActions>
                </Paper> */}

                {/* {answerSubmitted && !hideDesriptions && (
                  <Paper className="question-content">
                    <Typography variant="h6" gutterBottom>
                      Answer Description
                    </Typography>
                    {Object.keys(answerDescriptions || {}).map(
                      (a) =>
                        answerDescriptions[a] && (
                          <div key={a}>
                            <Typography
                              color="textSecondary"
                              variant="subtitle2"
                            >
                              {answers[a]}
                            </Typography>

                            <Typography variant="body1" gutterBottom>

                              <TinyView value={answerDescriptions[a]} />
                            </Typography>
                            <br />
                          </div>
                        )
                    )}
                    {Object.values(answerDescriptions || {}).reduce(
                      (a, i) => a && i.length === 0,
                      true
                    ) && (
                      <Typography color="textSecondary">
                        No Answer descriptions
                      </Typography>
                    )}
                  </Paper>
                )} */}

                {/* {description &&
                  description.length > 0 &&
                  answerSubmitted &&
                  !hideDesriptions && (
                    <Paper className="question-content">
                      <Typography variant="h6">Study Notes</Typography>
                      <span
                        className="question-text"
                        dangerouslySetInnerHTML={{
                          __html: description,
                        }}
                      />
                    </Paper>
                  )} */}

                {/* {helpfulLinks &&
                  helpfulLinks.length > 0 &&
                  answerSubmitted &&
                  !hideDesriptions && (
                    <Paper className="question-content">
                      <Typography variant="h6">Helpful Links</Typography>
                      {helpfulLinks.map((item, k) => (
                        <span key={k}>
                          {item.link &&
                            (item.link.match(/^http/) ? (
                              <Link href={item.link} target="_blank">
                                {" "}
                                {item.reason}
                              </Link>
                            ) : (
                              <div className="iframe-container">
                                <span
                                  className="question-text"
                                  dangerouslySetInnerHTML={{
                                    __html: item.link,
                                  }}
                                />
                              </div>
                            ))}
                          <br />
                        </span>
                      ))}
                    </Paper>
                  )} */}

                {/* {isAdmin && answerSubmitted && !hideDesriptions && (
                  <div className={classes.root}>
                    <AppBar position="static" color="default">
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        aria-label="full width tabs example"
                      >
                        <Tab label="Study Notes" {...a11yProps(0)} />

                        <Tab label="Revision Notes" {...a11yProps(1)} />

                        <Tab label="Explanation Notes" {...a11yProps(2)} />
                      </Tabs>
                    </AppBar>
                    <SwipeableViews
                      axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                      index={value}
                      onChangeIndex={handleChangeIndex}
                    >
                      <TabPanel value={value} index={0} dir={theme.direction}>
                        {_description && _description.length > 0 && (
                          <>
                            <Typography variant="h6">Study Notes</Typography>

                            <TinyView value={_description} />
                          </>
                        )}
                      </TabPanel>
                      <TabPanel value={value} index={1} dir={theme.direction}>
                        {revisionNotes && revisionNotes.length > 0 && (
                          <>
                            <Typography variant="h6">
                              RevisionNotes Notes
                            </Typography>
                            <TinyView value={revisionNotes} />
                          </>
                        )}
                      </TabPanel>
                      <TabPanel value={value} index={2} dir={theme.direction}>
                        {explanationNotes && explanationNotes.length > 0 && (
                          <>
                            <Typography variant="h6">
                              ExplanationNotes Notes
                            </Typography>
                            <TinyView value={explanationNotes} />
                          </>
                        )}
                      </TabPanel>
                    </SwipeableViews>
                  </div>
                )} */}

                {/* {answerSubmitted && (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={nextQuestion}
                      className="float-right-button"
                    >
                      Next Question
                      <NavigateNextIcon />
                    </Button>
                    <Button
                      className={"float-right-button"}
                      onClick={() => {
                        setReportmodal(true);
                      }}
                    >
                      <ReportIcon /> {"  "}Feedback
                    </Button>
                  </>
                )} */}
              </>
            ) : (
              <>
                {/* <Typography
                  variant="body1"
                  align="center"
                  color="textPrimary"
                  gutterBottom
                >
                  Collecting Questions..
                </Typography>
                <LinearProgress variant="query" /> */}
              </>
            )}
          </Col>
          {/* <Grid item xs={12} sm={4} md={3}>
            <Button
              className="ref-button"
              aria-describedby={id}
              variant="outlined"
              color="primary"
              fullWidth
              onClick={handleRefPanelClick}
            >
              reference values
            </Button>
            <Popover
              id={refId}
              open={openRefPanel}
              anchorEl={anchorEl}
              onClose={handleClose}
              className="ref-body"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Reference />
            </Popover>

            <Paper className="question-content question-list">
              <Typography variant="subtitle2">Question List</Typography>
              <List dense>
                {submissions &&
                  qIds.map((id, i) => (
                    <ListItem
                      key={i}
                      className={currentQ === i ? "current-item" : ""}
                      button
                      onClick={() => {
                        setCurrentQuestion(i);
                      }}
                    >
                      <ListItemAvatar>
                        <span>{i + 1}</span>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <>
                            {submissions[id] ? (
                              hideDesriptions ? (
                                "Answered"
                              ) : (
                                <Chip
                                  size="small"
                                  avatar={
                                    <Avatar>
                                      {submissions[id].correct ? (
                                        <CheckIcon />
                                      ) : (
                                        <ClearIcon />
                                      )}
                                    </Avatar>
                                  }
                                  label={
                                    submissions[id].correct
                                      ? "Correct"
                                      : "Wrong"
                                  }
                                  className={`${
                                    submissions[id].correct
                                      ? "correct"
                                      : "wrong"
                                  } chip`}
                                />
                              )
                            ) : (
                              <span>-- --</span>
                            )}
                          </>
                        }
                      />
                      <ListItemSecondaryAction>
                        {pastPaperQuestions[id] && (
                          <Tooltip title="This is a high yield or a recall questiona.">
                            <StarsIcon />
                          </Tooltip>
                        )}
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
              </List>
            </Paper>
          </Grid>
        */}
        </Row>
        {exam && (
          <ExamModal
            match={match}
            exam={exam}
            expired={expiredExam}
            submissions={submissions}
            open={open}
            setOpen={setOpen}
            setRenderAll={setRenderAll}
            setShowDescription={setShowDescription}
          />
        )}

        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={snackBar.open}
          autoHideDuration={4000}
          onClose={handleCloseSnackBar}
          message={<span id="message-id">{snackBar.msg}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={handleCloseSnackBar}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
        <ReportModal
          open={reportmodal}
          onClose={reportModelClosed}
          question={fullQuestions[currentQ]}
        />
        <SuggestionModal
          open={suggestionmodal}
          onClose={suggestionModelClosed}
          question={fullQuestions[currentQ]}
        />
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          open={mock && mockModal}
          onClose={() => {
            setMockModal(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Mock Exam</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {endedMock
                ? `For this exam you'll have ${parseInt(
                    Object.keys(allQuestions).length - mockUsedSec / 60
                  )} Minutes to answer all ${
                    Object.keys(allQuestions).length
                  } Questions`
                : `This exam has ended. You can review your past answers`}
            </DialogContentText>
            {endedMock && (
              <>
                <h6 class="MuiTypography-root MuiTypography-h6 MuiTypography-alignCenter">
                  You {mockCorrect >= mockPass ? "passed" : "failed"} the exam
                </h6>
                <h6 class="MuiTypography-root MuiTypography-h6 MuiTypography-alignCenter">
                  Score: {mockCorrect} of {Object.keys(allQuestions).length} - (
                  {parseFloat(
                    (mockCorrect / Object.keys(allQuestions).length) * 100
                  ).toFixed(2)}
                  %)
                </h6>
              </>
            )}
            <DialogActions>
              <Button
                onClick={() => history.goBack()}
                color="primary"
                disabled={loading}
              >
                Back to Dashboard
              </Button>
              <Button
                onClick={startMockExam}
                color="primary"
                disabled={loading}
              >
                {endedMock ? "Review answers" : "Start the exam"}
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </Container>
      {/* <Footer /> */}
    </>
  );
};

export default Questions;
